import React, { useState } from "react";
import {
  Button,
  Form,
  Radio,
  Input,
  RadioGroup,
  SelectPicker,
  DatePicker,
  useToaster,
  Notification,
  IconButton,
  Modal,
  AutoComplete,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";

import { AdminID } from "../component/data";
import { useSelector } from "react-redux";
import { lastDay } from "../Constants";
import ExportIcon from "@rsuite/icons/Export";
import * as XLSX from "xlsx";
import { Table2XLSX } from "../functions";
const MarkAttendance = () => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [text, setText] = useState("");
  const [desc, setDesc] = useState("");
  const [rate, setRate] = useState("");
  const [labour, setLabour] = useState("");
  const [radio, setRadio] = useState("A");
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const toaster = useToaster();
  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");

  const [selectedName1, setselectedName1] = useState("");
  const [selectedNameID1, setselectedNameID1] = useState("");

  const [selectedName2, setselectedName2] = useState("");
  const [selectedNameID2, setselectedNameID2] = useState("");

  const [selectedSupervisor, setselectedSupervisor] = useState("");
  const [selectedSupervisorID, setselectedSupervisorID] = useState("");

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const [siteList, setSiteList] = useState([]);
  const [sup, setsup] = useState("");
  const [supID, setsupID] = useState("");
  const [attendance, setAttendance] = useState("");
  const [sitename, setSitename] = useState("");
  const [selectedLabour, setSelectedLabour] = useState("");
  const [selectedDate1, setselectedDate1] = useState(new Date());
  const [films1, setFilms1] = useState([]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLabourList();
      getSitesList();
      getUsersList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const column = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "SiteName", title: "Site" },
    { field: "Rate", title: "Rate" },
    { field: "Salary", title: "Salary" },
    { field: "Total", title: "Total" },
  ];

  const columnUser = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "Supervisor", title: "Supervisor" },
  ];

  let i = 0;
  const Attendance = [
    { key: ++i, label: "Absent", value: "0.00" },
    { key: ++i, label: "Half Date", value: "0.50" },
    { key: ++i, label: "Full Day", value: "1.00" },
    { key: ++i, label: "One & Half", value: "1.50" },
    { key: ++i, label: "Quarter Two", value: "1.75" },
    { key: ++i, label: "Double Pay", value: "2.00" },
    { key: ++i, label: "Two & Half Pay", value: "2.50" },
    { key: ++i, label: "Triple Pay", value: "3.00" },
    { key: ++i, label: "Triple & Half Pay", value: "3.50" },
  ];
  const column2 = [
    { field: "Name", title: "Name" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "SiteName", title: "Site" },
    { field: "Present", title: "Present" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Salary", title: "Salary" },
    { field: "Total", title: "Total" },
  ];

  const column2User = [
    { field: "Name", title: "Name" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "Present", title: "Present" },
  ];

  const RetriveSiteName = () => {
    axios
      .post("https://api.laxmaninterior.in/RetrieveSiteName.php", {
        ID: "",
        Date: moment().format("YYYY-MM-DD"),
      })
      .then((a) => {
        const result = a.data;
        console.log(result);
        if (result.status === 1) {
          setSitename(result.Data[0].SiteName);
        } else {
          setSitename("");
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("https://api.laxmaninterior.in/GetLaboursForDropDown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setLabourList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setRadio(e);
  };

  function handleExport() {
    setProcessing1(true);
    const table = document.getElementById("TableData");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "LabourAttendance.xlsx");
    /*     if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing1(false);
      return;
    }
    const datatoSend = { LabourID: selectedNameID };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByLabourExport.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Labour");
          XLSX.writeFile(wb, "LabourTransactions.xlsx");
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No Records
            </Notification>,
            { placement: "topEnd" }
          );
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
      }); */
    setProcessing1(false);
  }

  const handleSearchforLabour = () => {
    let date = new Date(selectedMonth);
    let firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format("YYYY-MM-DD");
    let lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ).format("YYYY-MM-DD");
    //        console.log(firstDay);
    //      console.log(lastDay);
    if (selectedNameID1 === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = {
      LabourID: selectedNameID1,
      SDate: firstDay,
      EDate: lastDay,
    };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetMonthlyDataForAttendance.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setAttendance(data1.AttendanceSum);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setAttendance(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  function handleExport1() {
    setProcessing1(true);
    if (selectedDate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing1(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDate).format("YYYY-MM-DD"),
      EndDate: moment(selectedDate).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetLabourTransactionByDateExport.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Labour");
          XLSX.writeFile(wb, "LabourTransactions.xlsx");
        }
        if (data1.status === 0) {
          console.log(data1.error);
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  }

  const handleSearchforLabourByDate = () => {
    setProcessing2(true);
    if (!selectedDate1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = { Date: moment(selectedDate1).format("YYYY-MM-DD") };
    axios
      .post(
        "https://api.laxmaninterior.in/GetAllLabourForAttendaceByDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  const ShowMarkAttendance = (
    name,
    LabourID,
    SupID,
    Supervisor,
    Present,
    rate1,
    SiteName
  ) => {
    handleOpen();
    getUsersList();
    setLabour(LabourID);
    setText(name);
    setselectedName(Supervisor);
    setselectedNameID(SupID);
    setDesc(Present);
    setRate(rate1);
    setSitename(SiteName);
  };

  const ShowMarkAttendance1 = (
    SupID,
    Supervisor,
    Present,
    rate1,
    dt,
    SiteName
  ) => {
    handleOpen1();
    getUsersList();
    setsup(Supervisor);
    setsupID(SupID);
    setDesc(Present);
    setRate(rate1);
    setselectedDate(dt);
    setSitename(SiteName);
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetUsersForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Mark = () => {
    //AdminID
    if (!selectedName) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedNameID) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!labour) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }
    const datatoSend = {
      LabourID: labour,
      Attendance: desc,
      AddedBy: AdminID,
      Rate: rate,
      Supervisor: selectedNameID,
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Sitename: sitename,
    };
    axios
      .post("https://api.laxmaninterior.in/MarkAttendanceWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabourByDate();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose1();
  };

  const Mark1 = () => {
    //AdminID
    if (!selectedName1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedNameID1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedDate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!supID) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      LabourID: selectedNameID1,
      Attendance: desc,
      AddedBy: AdminID,
      Rate: rate,
      Supervisor: supID,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      Sitename: sitename,
    };
    axios
      .post("https://api.laxmaninterior.in/MarkAttendanceWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabour();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose1();
  };

  const handleSearchforLabourBySupervisor = () => {
    setProcessing2(true);
    if (!selectedDate1) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Supervisor: selectedSupervisorID,
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetAllLabourForAttendanceBySupervisor.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };
  const Mark2 = () => {
    //AdminID
    if (!selectedName) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!selectedNameID) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!labour) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!desc) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Attendance
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    if (!rate) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          No Per Day rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }
    const datatoSend = {
      LabourID: labour,
      Attendance: desc,
      AddedBy: AdminID,
      Rate: rate,
      Supervisor: selectedNameID,
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Sitename: sitename,
    };
    axios
      .post("https://api.laxmaninterior.in/MarkAttendanceWeb.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        // console.log(data1);

        if (data1.status === 1) {
          handleClose();
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Successfully updated
            </Notification>,
            { placement: "topEnd" }
          );
          handleSearchforLabourBySupervisor();
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
    handleClose2();
  };
  const ShowMarkAttendance2 = (
    name,
    LabourID,
    SupID,
    Supervisor,
    Present,
    rate1,
    SiteName
  ) => {
    handleOpen2();
    getUsersList();
    setLabour(LabourID);
    setText(name);
    setselectedName(Supervisor);
    setselectedNameID(SupID);
    setDesc(Present);
    setRate(rate1);
    setSitename(SiteName);
  };
  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Attendance</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              setRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Labour wise</Radio>
            <Radio value="D">Date wise</Radio>
            <Radio value="C">Supervisor wise</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Month:</Form.ControlLabel>
                    <DatePicker
                      oneTap
                      cleanable={false}
                      format="MMM-yyyy"
                      limitStartYear={2023}
                      defaultValue={lastDay}
                      ranges={[]}
                      onSelect={(e) => setSelectedMonth(e)}
                      style={{ width: 200 }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>Labour Name:</Form.ControlLabel>
                    <SelectPicker
                      cleanable={false}
                      data={LabourList}
                      appearance="default"
                      placeholder="Select Labour"
                      style={{ width: 224 }}
                      value={selectedName1}
                      onSelect={(e, Item) => {
                        setselectedName1(e);
                        console.log(Item);
                        setselectedNameID1(Item.key);
                      }}
                      onChange={() => setdataList([])}
                    />

                    <IconButton
                      onClick={handleSearchforLabour}
                      loading={processing}
                      icon={<SearchIcon />}
                    >
                      Refresh
                    </IconButton>
                    <Table2XLSX />
                  </Form.Group>
                </div>
                <div style={{ justifyContent: "center" }}>
                  <div>
                    <div> Attendance : {attendance}</div>
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "D" && (
            <>
              <Form.Group>
                <Form.ControlLabel>Date:</Form.ControlLabel>

                <DatePicker
                  cleanable={false}
                  appearance="default"
                  placeholder="Select Date"
                  oneTap
                  format="dd-MM-yyyy"
                  style={{ width: 150 }}
                  value={selectedDate1}
                  onChange={(e) => {
                    console.log(e);
                    setdataList2([]);
                    setselectedDate1(e);
                  }}
                />

                <IconButton
                  onClick={handleSearchforLabourByDate}
                  loading={processing2}
                  icon={<SearchIcon />}
                >
                  Refresh
                </IconButton>
                <Table2XLSX />
              </Form.Group>
            </>
          )}

          {radio === "C" && (
            <>
              <Form.Group>
                <Form.ControlLabel>Date:</Form.ControlLabel>
                <DatePicker
                  appearance="default"
                  cleanable={false}
                  placeholder="Select Date"
                  oneTap
                  style={{ width: 150 }}
                  value={selectedDate1}
                  onChange={(e) => {
                    console.log(e);
                    setdataList2([]);
                    setselectedDate1(e);
                  }}
                />
                &nbsp; &nbsp; &nbsp;
                <Form.ControlLabel>Supervisor:</Form.ControlLabel>
                <SelectPicker
                  cleanable={false}
                  data={usersList}
                  appearance="default"
                  placeholder="Default"
                  style={{ width: 200 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    setselectedSupervisor(e);
                    setselectedSupervisorID(Item.id);
                  }}
                  value={selectedSupervisor}
                />
                <IconButton
                  onClick={handleSearchforLabourBySupervisor}
                  loading={processing2}
                  icon={<SearchIcon />}
                >
                  Refresh
                </IconButton>
                <Table2XLSX />
              </Form.Group>
            </>
          )}
        </Form>

        <div>
          <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate1).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedName}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={Attendance}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={Mark} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal open={open1} onClose={handleClose1} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={selectedName1}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    defaultValue={sup}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setsup(e);
                      setsupID(Item.id);
                    }}
                    value={sup}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    data={Attendance}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={Mark1} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose1} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal open={open2} onClose={handleClose2} size="xs">
            <Modal.Header>
              <Modal.Title>{`Mark Attendance for ${moment(selectedDate1).format(
                "Do-MMM-YYYY"
              )}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedSupervisorID}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>SiteName</Form.ControlLabel>
                  <SelectPicker
                    data={siteList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      // console.log(Item);
                      setSitename(e);
                    }}
                    value={sitename}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    data={Attendance}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={Mark2} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose2} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="InputContainer">
        {radio === "A" &&
          (Type === "Admin" ? (
            <Table striped bordered hover id="TableData">
              <thead>
                <tr>
                  {column.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList.map((a) => (
                  <tr key={a.Date}>
                    <td>{a.Date}</td>
                    <td>{selectedName1}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance1(
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.Date,
                            a.Sitename
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                    <td>{a.Supervisor}</td>
                    <td>{a.Sitename}</td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.PerDayRate)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Salary)}
                    </td>

                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table striped bordered hover id="TableData">
              <thead>
                <tr>
                  {columnUser.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList.map((a) => (
                  <tr key={a.Date}>
                    <td>{a.Date}</td>
                    <td>{selectedName1}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance1(
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.Date,
                            a.Sitename
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                    <td>{a.Supervisor}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
        {radio === "D" &&
          (Type === "Admin" ? (
            <Table id="TableData">
              <thead>
                <tr>
                  {column2.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList2.map((a) => (
                  <tr key={a.key}>
                    <td>{a.Name}</td>
                    <td>{a.Supervisor}</td>
                    <td>{a.Sitename}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance(
                            a.Name,
                            a.key,
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.Sitename
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.PerDayRate)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Salary)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table id="TableData">
              <thead>
                <tr>
                  {column2User.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList2.map((a) => (
                  <tr key={a.key}>
                    <td>{a.Name}</td>
                    <td>{a.Supervisor}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance(
                            a.Name,
                            a.key,
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.SiteName
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
        {radio === "C" &&
          (Type === "Admin" ? (
            <Table id="TableData">
              <thead>
                <tr>
                  {column2.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList2.map((a) => (
                  <tr key={a.key}>
                    <td>{a.Name}</td>
                    <td>{a.Supervisor}</td>
                    <td>{a.Sitename}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance2(
                            a.Name,
                            a.key,
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.Sitename
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.PerDayRate)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Salary)}
                    </td>
                    <td>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table id="TableData">
              <thead>
                <tr>
                  {column2User.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataList2.map((a) => (
                  <tr key={a.key}>
                    <td>{a.Name}</td>
                    <td>{a.Supervisor}</td>
                    <td>
                      {" "}
                      <Button
                        style={{ marginHorizontal: 10, fontSize: 15 }}
                        color={`${a.Present > 0 ? "green" : "red"}`}
                        appearance="link"
                        onClick={() =>
                          ShowMarkAttendance(
                            a.Name,
                            a.key,
                            a.SupID,
                            a.Supervisor,
                            a.Present,
                            a.PerDayRate,
                            a.SiteName
                          )
                        }
                        loading={processing1}
                      >
                        {a.Present}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
      </div>
    </div>
  );
};

export default MarkAttendance;
