import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  SelectPicker,
  DatePicker,
  useToaster,
  Notification,
  IconButton,
  Modal,
  AutoComplete,
  Whisper,
  Tooltip,
  InputGroup,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmAlert } from "react-confirm-alert"; // Import
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";
import * as XLSX from "xlsx";
import { AdminID } from "../component/data";
import { useSelector } from "react-redux";
import { lastDay } from "../Constants";
import ExportIcon from "@rsuite/icons/Export";
import sendEmail from "../component/sendEmail";
import { Table2XLSX } from "../functions";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const OtherLabours = () => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;
  const [open, setOpen] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [processing, setProcessing] = useState(false);
  const toaster = useToaster();
  const [attendance, setAttendance] = useState("");
  const [sitename, setSitename] = useState("");
  const [searchText, setSearchText] = useState("");
  /* const [films1, setFilms1] = useState([]); */
  const [siteList, setSiteList] = useState([]);
  const [amnt, setAmnt] = useState(0);
  const [labourName, setLabourName] = useState("");
  const [rate, setRate] = useState("");
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState(new Date());

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLabourList();
      getSitesList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const column = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Attendance", title: "Attendance" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];

  const columnUser = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];

  let i = 0;
  /* const Attendance = [
    { key: ++i, label: "Absent", value: "0.00" },
    { key: ++i, label: "Half Date", value: "0.50" },
    { key: ++i, label: "Full Day", value: "1.00" },
    { key: ++i, label: "One & Half", value: "1.50" },
    { key: ++i, label: "Quarter Two", value: "1.75" },
    { key: ++i, label: "Double Pay", value: "2.00" },
    { key: ++i, label: "Two & Half Pay", value: "2.50" },
    { key: ++i, label: "Triple Pay", value: "3.00" },
    { key: ++i, label: "Triple & Half Pay", value: "3.50" },
  ]; */
  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("https://api.laxmaninterior.in/GetAllOtherWorkers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const test = data1.Data.map(({ WorkerName }) => WorkerName);
          // console.log(test);
          setLabourList(test);
        }
        if (data1.status === 0) {
          setLabourList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /*   const RetriveSiteName = () => {
    axios
      .post("https://api.laxmaninterior.in/RetrieveSiteName.php", {
        ID: "",
        Date: moment().format("YYYY-MM-DD"),
      })
      .then((a) => {
        const result = a.data;
        console.log(result);
        if (result.status === 1) {
          setSitename(result.Data[0].SiteName);
        } else {
          setSitename("");
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  }; */

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    getSitesList();
    setOpen(true);
  };

  const handleSearchforLabour = () => {
    let date = new Date(selectedMonth);
    let firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format("YYYY-MM-DD");
    let lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ).format("YYYY-MM-DD");
    //        console.log(firstDay);
    //      console.log(lastDay);
    if (selectedMonth === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Month: lastDay };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetAllDataForOtherWorker.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setFilteredList(data1.Data);
          setAmnt(data1.Total);
          setFilteredTotal(data1.Total);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setdataList([]);
          setFilteredList([]);
          setAmnt(0);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
        setdataList([]);
        setAmnt(0);
      });
  };

  function handleExport() {
    setProcessing(true);

    const table = document.getElementById("TableData");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "LabourList.xlsx");

    setProcessing(false);
  }

  useEffect(() => {
    var isSubscribed = true;
    if (isSubscribed) {
      if (searchText === "") {
        setFilteredList(dataList);
        setFilteredTotal(amnt);
      } else {
        let filterData = dataList.filter((item) =>
          item.Sitename.toLowerCase().includes(searchText)
        );
        let total = 0;
        filterData.map((item) => {
          total = parseInt(item.Amount) + total;
        });
        setFilteredList(filterData);
        setFilteredTotal(total);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [searchText]);
  /* toaster.push(
    <Notification type="error" header="Error" duration={1000}>
      Please select date
    </Notification>,
    { placement: "topEnd" }
  ); */

  const AddOtherLabour = () => {
    axios
      .post("https://api.laxmaninterior.in/MarkAttendanceForOther.php", {
        Labour: labourName,
        Attendance: attendance || 0,
        Date: moment(new Date(date)).format("YYYY-MM-DD"),
        AddedBy: AdminID,
        Rate: rate,
        Supervisor: AdminID,
        Notes: desc,
        Sitename: sitename,
      })
      .then((a) => {
        const result = a.data;
        //console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="Added" duration={1000}>
              Added Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          handleClose();
          handleSearchforLabour();
        }
        if (result.status === 2) {
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Something went wrong, please try again later.
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDelete = (id, data = { Name: "", Date: "", Attendance: "" }) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Attendance on ${data.Date}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromOtherWorker.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  handleSearchforLabour();
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Attendance ${data.Attendance} on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Attendance on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Other Workers`,
                    "Error in Deleting from Other Workers"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Other Worker(${data.Name})`,
                  "Error in Deleting from Other Worker"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Attendance</h4>
      </Header>
      <div className="InputContainer">
        <Form layout="inline">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <Form.Group>
                <Form.ControlLabel>Month:</Form.ControlLabel>
                <DatePicker
                  oneTap
                  format="MMM-yyyy"
                  cleanable={false}
                  defaultValue={lastDay}
                  ranges={[]}
                  onSelect={(e) => setSelectedMonth(e)}
                  style={{ width: 200 }}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforLabour}
                  loading={processing}
                  icon={<SearchIcon />}
                >
                  Refresh
                </IconButton>
                &nbsp;
                <IconButton
                  color="green"
                  appearance="primary"
                  onClick={handleOpen}
                  loading={processing}
                  icon={<AddOutlineIcon />}
                >
                  Add
                </IconButton>
              </Form.Group>
            </div>
            <div style={{ justifyContent: "center" }}>
              <div>&nbsp;</div>
            </div>
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <div>
                {Type === "Admin" && (
                  <div>
                    {" "}
                    <b>Total</b> :{" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(filteredTotal)}
                  </div>
                )}
                &nbsp;
              </div>
            </div>
          </div>
        </Form>

        <div>
          <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
              <Modal.Title>Attendance for Other Workers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <AutoComplete
                    data={LabourList}
                    value={labourName}
                    onChange={(e) => setLabourName(e)}
                    style={{ width: 240 }}
                  />
                </Form.Group>

                <Form.Group>
                  <InputGroup style={{ width: "240px" }}>
                    <InputGroup.Addon>Per Day (₹)</InputGroup.Addon>
                    <Input
                      value={rate}
                      onChange={(e) => {
                        setRate(e);
                      }}
                    />
                    <InputGroup.Addon>.00</InputGroup.Addon>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 100 }}
                    name="Attendance"
                    value={attendance}
                    onChange={(e) => {
                      setAttendance(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Date:</Form.ControlLabel>

                  <DatePicker
                    appearance="default"
                    placeholder="Select Date"
                    format="dd-MMM-yyyy"
                    oneTap
                    style={{ width: 150 }}
                    value={date}
                    onChange={(e) => {
                      console.log(e);
                      setDate(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Site:</Form.ControlLabel>
                  <SelectPicker
                    data={siteList}
                    appearance="default"
                    placeholder="Select Site"
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      setSitename(e);
                    }}
                    value={sitename}
                  />
                </Form.Group>

                <Form.Group controlId="textarea-6">
                  <Form.Control
                    name="textarea"
                    placeholder="Additional"
                    rows={1}
                    accepter={Textarea}
                    value={desc}
                    style={{ resize: "none", width: 240 }}
                    onChange={(e) => setDesc(e)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={AddOtherLabour} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="InputContainer" style={{ marginBottom: 10 }}>
        <Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "40%",
            }}
          >
            <Input value={searchText} onChange={(e) => setSearchText(e)} />
            &nbsp; &nbsp;
            <Table2XLSX />
          </div>
        </Form>
      </div>
      <div className="InputContainer">
        {Type === "Admin" ? (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>{a.Sitename}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.Name,
                          Date: a.Date,
                          Attendance: a.Attendance,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {columnUser.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>{a.Sitename}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default OtherLabours;
