import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  useToaster,
  Notification,
  DatePicker,
  Tooltip,
  Whisper,
  IconButton,
  SelectPicker,
  InputGroup,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import ReloadIcon from "@rsuite/icons/Reload";
import * as XLSX from "xlsx";
import axios from "axios";
import { AdminID } from "../Constants";
import VisibleIcon from "@rsuite/icons/Visible";
import SendIcon from "@rsuite/icons/Send";
import EyeCloseIcon from "@rsuite/icons/EyeClose";
import moment from "moment";
import ExportIcon from "@rsuite/icons/Export";
import { validateNumber, validateOnlyNumber } from "../functions";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
// TODO:Display pic on labour name click
const ViewLabours = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [open1, setOpen1] = React.useState(false);
  const [oldRate, setOldRate] = React.useState(0);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const [labourList, setLabourList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [updateLabour, setUpdateLabour] = useState({
    ID: "",
    Name: "",
    Desc: "",
    PerDayRate: "",
    EffectiveFrom: new Date(),
    Type: "",
    Supervisor: "",
    SupervisorID: "",
    Mobile: "",
  });
  const column = [
    { field: "Name", title: "Name" },
    { field: "Type", title: "Type" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Additional", title: "Additional" },
    { field: "Supervisor", title: "Supervisor" },
    { field: "Mobile", title: "Mobile" },
  ];
  const [value, setValue] = React.useState({
    Name: "",
    Type: "",
    PerDayRate: 0,
    Additional: "",
    Mobile: "",
  });
  const toaster = useToaster();
  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);
  /*   const EditModal = () => {
    return (
      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title>Update Labour Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Supervisor</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedName}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName(e);
                      setselectedNameID(Item.id);
                    }}
                    value={selectedName}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Attendance</Form.ControlLabel>
                  <SelectPicker
                    data={Attendance}
                    appearance="default"
                    placeholder="Select"
                    defaultValue={desc}
                    style={{ width: 220 }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }; */

  function handleExport() {
    setProcessing2(true);

    const table = document.getElementById("TableData");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "LabourList.xlsx");

    setProcessing2(false);
  }

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetUsersForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setDataList(data1.Data)
          data1.Data.forEach((r) => {
            setUsersList((a) => {
              return [...a, { id: r.UniqueID, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setProcessing1(true);
    getUsersList();
    axios
      .get("https://api.laxmaninterior.in/GetAllLaboursForWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setLabourList(data1.Data);
          setCount(data1.Count);
          var outputData = labourList.map(Object.values);
          setData(outputData);
        }
        if (data1.status === 0) {
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  };

  const SaveData = async () => {
    setProcessing(true);

    if (value.Name === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Labour Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Type === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.PerDayRate === "" || value.PerDayRate === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Per Day Rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    axios
      .post(
        "https://api.laxmaninterior.in/AddLabour.php",
        {
          Name: value.Name,
          Type: value.Type,
          Note: value.Additional,
          Picture: "",
          Rate: value.PerDayRate,
          Mobile: value.Mobile,
          Supervisor: selectedNameID,
          AddedBy: "qMbiDYDaWZOYY3rBWjFgcUvJoX22",
        },
        {
          headers: {
            "Content-type": "application.json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        console.log(result.status);
        if (result.status === 2) {
          toaster.push(
            <Notification type="Error" header="Error" duration={1000}>
              `${value.Name} already exists.`
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Saved Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
        }
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Saved Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
    setProcessing(false);
  };

  function clearInput() {
    setValue({
      Name: "",
      Additional: "",
    });
  }
  function handleEdit(data) {
    handleOpen();
    setUpdateLabour((a) => {
      return {
        ...a,
        ID: data.SrNo,
        Name: data.Name,
        Desc: data.Additional,
        PerDayRate: data.PerDayRate,
        Type: data.LabourType,
        Supervisor: data.Supervisor,
        SupervisorID: data.SupervisorID,
        Mobile: data.Mobile,
      };
    });
    console.log(data);
    setOldRate(data.PerDayRate);
  }

  function saveUpdated() {
    if (updateLabour.Name === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Labour Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.LabourType === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.PerDayRate === "" || updateLabour.PerDayRate === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Per Day Rate
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Supervisor === "" || updateLabour.SupervisorID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select supervisor
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (updateLabour.Mobile !== "" && !validateNumber(updateLabour.Mobile)) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please enter valid 10 digit mobile no.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    console.log(updateLabour);
    if (
      oldRate !== parseInt(updateLabour.PerDayRate) &&
      updateLabour.EffectiveFrom === ""
    ) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select effective from date to update new per day rate.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    let datatoSend = {};
    console.log(parseInt(oldRate) !== parseInt(updateLabour.PerDayRate));
    if (oldRate !== parseInt(updateLabour.PerDayRate)) {
      datatoSend = {
        ID: updateLabour.ID,
        Name: updateLabour.Name,
        Desc: updateLabour.Desc,
        PerDayRate: updateLabour.PerDayRate,
        Type: updateLabour.Type,
        Supervisor: updateLabour.Supervisor,
        SupervisorID: updateLabour.SupervisorID,
        Mobile: updateLabour.Mobile,
        NewRate: true,
        EffectiveFrom: moment(updateLabour.EffectiveFrom).format("YYYY-MM-DD"),
      };
    } else {
      datatoSend = {
        ID: data.SrNo,
        Name: data.Name,
        Desc: data.Additional,
        PerDayRate: data.PerDayRate,
        Type: data.LabourType,
        Supervisor: data.Supervisor,
        SupervisorID: data.SupervisorID,
        Mobile: updateLabour.Mobile,
        NewRate: false,
      };
    }

    axios
      .post(
        "https://api.laxmaninterior.in/UpdateLabourForWeb.php",
        datatoSend,
        {
          headers: {
            "Content-type": "application.json",
          },
        }
      )
      .then((response) => {
        const result = response.data;
        console.log(result.status);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Saved Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
          handleClose();
        }
        if (result.status === 2) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Updated Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
          handleClose();
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Error occured
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleStatus(ID, Status) {
    axios
      .post("https://api.laxmaninterior.in/ChangeStatus.php", {
        LabourID: ID,
        Status,
      })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              Updated Successfully
            </Notification>,
            { placement: "topEnd" }
          );
          handleRefresh();
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleDelete(a) {
    console.log(a);
  }
  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Labours</h4>
      </Header>
      <div className="Subbody">
        <Form layout="inline">
          <Form.Group>
            <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
              <Input
                style={{ width: 300 }}
                placeholder="Enter Labour name"
                value={value.Name}
                onChange={(e) =>
                  setValue((a) => {
                    return { ...a, Name: e };
                  })
                }
              />
            </Whisper>
          </Form.Group>
          <Form.Group>
            <Input
              style={{ width: 300 }}
              placeholder="Enter Labour Type"
              value={value.Type}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Type: e };
                })
              }
            />
          </Form.Group>

          <Form.Group>
            <InputGroup style={{ width: "240px" }}>
              <InputGroup.Addon>Per Day (₹)</InputGroup.Addon>
              <Input
                value={value.PerDayRate}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, PerDayRate: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="textarea-6">
            <Form.Control
              name="textarea"
              placeholder="Additional"
              rows={1}
              accepter={Textarea}
              value={value.Additional}
              style={{ resize: "none", width: 200 }}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Additional: e };
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="textarea-7">
            <Form.Control
              name="textarea-7"
              placeholder="Mobile"
              rows={1}
              accepter={Textarea}
              value={value.Mobile}
              style={{ resize: "none", width: 200 }}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Mobile: e };
                })
              }
            />
          </Form.Group>

          <Form.Group controlId="textarea-6">
            <SelectPicker
              data={usersList}
              appearance="default"
              placeholder="Select Supervisor"
              style={{ width: 200 }}
              key="id"
              sort={() => {
                return (a, b) => {
                  return compare(a.value, b.value);
                };
              }}
              onSelect={(e, Item) => {
                console.log(Item);
                setselectedName(e);
                setselectedNameID(Item.id);
              }}
              value={selectedName}
            />
          </Form.Group>

          <IconButton
            color="green"
            appearance="primary"
            onClick={SaveData}
            loading={processing}
            icon={<AddOutlineIcon />}
          >
            Add
          </IconButton>

          <Button
            color="green"
            appearance="primary"
            onClick={handleRefresh}
            loading={processing1}
          >
            <ReloadIcon style={{ margin: "5px" }} /> Refresh
          </Button>
          <Button
            color="green"
            appearance="subtle"
            onClick={handleExport}
            loading={processing2}
          >
            <ExportIcon style={{ margin: "5px" }} /> Export
          </Button>

          {/* Info: ===================================================== */}

          <div>
            <Modal open={open} onClose={handleClose} size="xs">
              <Modal.Header>
                <Modal.Title>Update Labour Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form fluid>
                  <Form.Group>
                    <Form.ControlLabel>Name</Form.ControlLabel>
                    <Form.Control
                      name="name"
                      value={updateLabour.Name}
                      onChange={(e) => {
                        setUpdateLabour((old) => {
                          return { ...old, Name: e };
                        });
                        //setText(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Supervisor</Form.ControlLabel>
                    <SelectPicker
                      data={usersList}
                      appearance="default"
                      placeholder="Default"
                      defaultValue={updateLabour.Supervisor}
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        setUpdateLabour((old) => {
                          return {
                            ...old,
                            Supervisor: e,
                            SupervisorID: Item.id,
                          };
                        });
                        console.log(Item);
                      }}
                      value={updateLabour.Supervisor}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Descriptions</Form.ControlLabel>
                    <Form.Control
                      rows={1}
                      name="textarea"
                      accepter={Textarea}
                      style={{ resize: "none" }}
                      value={updateLabour.Desc}
                      onChange={(e) => {
                        setUpdateLabour((a) => {
                          return { ...a, Desc: e };
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>Type</Form.ControlLabel>
                    <Form.Control
                      rows={1}
                      name="textarea"
                      accepter={Textarea}
                      style={{ resize: "none" }}
                      value={updateLabour.Type}
                      onChange={(e) => {
                        setUpdateLabour((a) => {
                          return { ...a, Type: e };
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>Mobile</Form.ControlLabel>
                    <Form.Control
                      rows={1}
                      name="textarea"
                      accepter={Textarea}
                      style={{ resize: "none" }}
                      value={updateLabour.Mobile}
                      onChange={(e) => {
                        setUpdateLabour((a) => {
                          return { ...a, Mobile: e };
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group>
                    <InputGroup style={{ width: "100%" }}>
                      <InputGroup.Addon>Per Day (₹)</InputGroup.Addon>
                      <Input
                        value={updateLabour.PerDayRate}
                        onChange={(e) => {
                          setUpdateLabour((a) => {
                            return { ...a, PerDayRate: e };
                          });
                        }}
                      />
                      <InputGroup.Addon>.00</InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Effective From:</Form.ControlLabel>

                    <DatePicker
                      appearance="default"
                      placeholder="Select Effective From Date"
                      format="dd-MM-yyyy"
                      oneTap
                      style={{ width: 250 }}
                      value={updateLabour.EffectiveFrom}
                      onChange={(e) => {
                        console.log(e);
                        setUpdateLabour((old) => {
                          return { ...old, EffectiveFrom: e };
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={saveUpdated} appearance="primary">
                  Submit
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`List of Labours (${count})`}</h5>
      </Header>
      <div className="InputContainer">
        <Table id="TableData">
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {labourList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.Name}</td>
                <td>{a.LabourType}</td>
                <td>{a.PerDayRate}</td>
                <td>{a.Additional}</td>
                <td>{a.Supervisor}</td>
                <td>{a.Mobile}</td>
                <td width={60}>
                  <IconButton
                    icon={<EditIcon />}
                    color="blue"
                    appearance="primary"
                    circle
                    onClick={() => {
                      handleEdit(a);
                    }}
                  />
                </td>
                <td width={60}>
                  <IconButton
                    icon={
                      a.Status === "Active" ? <VisibleIcon /> : <EyeCloseIcon />
                    }
                    color={a.Status === "Active" ? "green" : "red"}
                    appearance="primary"
                    circle
                    onClick={() => {
                      handleStatus(a.SrNo, a.Status);
                    }}
                  />
                </td>
                <td width={60}>
                  <IconButton
                    icon={<SendIcon />}
                    color="primary"
                    appearance="primary"
                    circle
                    href={`https://salary.laxmaninterior.in/Details/${a.UniqueID}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ViewLabours;
