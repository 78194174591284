import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./pages/Main";
import "rsuite/dist/rsuite.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./functions";
import AddNew from "./pages/AddNew";
import ViewAll from "./pages/ViewAll";
import Dashboard from "./pages/Dashboard";
import ManageUsers from "./pages/ManageUsers";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import Suppliers from "./pages/Suppliers";
import ViewClients from "./pages/ViewClients";
import ViewSuppliers from "./pages/ViewSuppliers";
import Test from "./pages/Test";
import AddClientTransaction from "./pages/AddClientTransaction";
import AddSupplierTransaction from "./pages/AddSupplierTransaction";
import ViewLabours from "./pages/ViewLabours";
import ViewAttendance from "./pages/ViewAttendance";
import GetSalary from "./pages/GetSalary";
import MarkAttendance from "./pages/MarkAttendance";
import ViewAllNew from "./pages/ViewAllNew";
import Summary from "./pages/Summary";
import OtherLabours from "./pages/OtherLabours";
import ManageSites from "./pages/ManageSites";
import Login from "./pages/LoginPage/LoginNew";

//import Login from "./pages/LoginPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/Test" element={<Test />} />
        <Route path="Home" element={<Home />} />
        <Route
          path="Main"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        >
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="AddNew" element={<AddNew />} />
          <Route path="ViewAll" element={<ViewAll />} />
          <Route path="ManageUsers" element={<ManageUsers />} />
          <Route path="Clients" element={<Clients />} />
          <Route path="ViewClients" element={<ViewClients />} />
          <Route
            path="ClientNewTransaction"
            element={<AddClientTransaction />}
          />
          <Route path="Suppliers" element={<Suppliers />} />
          <Route path="ViewSuppliers" element={<ViewSuppliers />} />
          <Route
            path="SupplierNewTransaction"
            element={<AddSupplierTransaction />}
          />
          <Route path="ViewLabours" element={<ViewLabours />} />
          <Route path="ViewAttendance" element={<ViewAttendance />} />
          <Route path="GetSalary" element={<GetSalary />} />
          <Route path="MarkAttendance" element={<MarkAttendance />} />
          <Route path="ViewAllNew" element={<ViewAllNew />} />
          <Route path="Summary" element={<Summary />} />
          <Route path="OtherLabours" element={<OtherLabours />} />
          <Route path="ManageSites" element={<ManageSites />} />
          <Route path="*" component={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
