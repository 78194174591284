import React, { useState, useLayoutEffect } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
} from "rsuite";
import axios from "axios";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";

import * as XLSX from "xlsx";
import SearchIcon from "@rsuite/icons/Search";
import TrashIcon from "@rsuite/icons/Trash";
import moment from "moment";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ExportIcon from "@rsuite/icons/Export";
import { Table2XLSX } from "../functions";
const ViewClients = () => {
  const [radio, setRadio] = useState("A");

  const [selectedNameID, setselectedNameID] = useState("");
  const [clientList, setClientList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [invoice, setInvoice] = useState(0);
  const [payment, setPayment] = useState(0);
  const toaster = useToaster();
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  const [column, setColumn] = useState([
    { field: "ClientName", title: "Client Name" },
    { field: "Date", title: "Date" },
    { field: "Type", title: "Type" },
    { field: "Amount", title: "Amount" },
    { field: "GST", title: "GST" },
    { field: "Note", title: "Note" },
  ]);
  const [selectedClient, setSelectedClient] = useState("");

  const getClientList = () => {
    setClientList([]);
    axios
      .get("https://api.laxmaninterior.in/GetClients.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setClientList((a) => {
              return [
                ...a,
                { id: r.SrNo, value: r.ClientName, label: r.ClientName },
              ];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = (id, data = { Name: "", Date: "", Amount: "" }) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    let text;
    //confirmAlert(options);

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s payment on ${data.Date} for Amount ${data.Amount}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromClient.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Client(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${data.Date} for Amount ${data.Amount} ?`,
                    `Deleted from Client(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Client(${data.Name}) Transaction`,
                    "Error in Deleting from Client Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Client(${data.Name}) Transaction`,
                  "Error in Deleting from Client Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const handleExport = () => {
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter client name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { ClientID: selectedNameID };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByClientExport.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Client");
          XLSX.writeFile(wb, "ClientTransactions.xlsx");
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleExport1 = () => {
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter client name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByDateExport.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Client");
          XLSX.writeFile(wb, "ClientTransactions.xlsx");
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleRadio = (e) => {
    setRadio(e);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) getClientList();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleSearchforClient = () => {
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter client name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { ClientID: selectedNameID };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByClient.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          setDataList(data1.Data);
          setPayment(data1.Sum);
          setInvoice(data1.Sum2);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearchforClientByDate = () => {
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter client name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsByDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          setDataList2(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Header>
        <h5 style={{ color: "white", fontSize: 25 }}>
          {radio === "A" &&
            `${
              selectedClient !== ""
                ? "Report by Client - " + selectedClient
                : "Report by Client"
            } `}
          {radio === "D" &&
            `Report from ${moment(selectedDateRange[0]).format(
              "DD-MMM-YYYY"
            )} to ${moment(selectedDateRange[1]).format("DD-MMM-YYYY")}`}
        </h5>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Report by Client</Radio>
            <Radio value="D">Date wise Search</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifuContent: "space-between",
              }}
            >
              <div style={{ flex: 0.6 }}>
                <Form.Group>
                  <Form.ControlLabel>Client Name:</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={clientList}
                    appearance="default"
                    placeholder="Select Client"
                    style={{ width: 224 }}
                    value={selectedClient}
                    onSelect={(e, Item) => {
                      setSelectedClient(e);
                      setselectedNameID(Item.id);
                    }}
                  />
                  &nbsp;
                  <IconButton
                    loading={processing}
                    onClick={handleSearchforClient}
                    icon={<SearchIcon />}
                  >
                    Search
                  </IconButton>
                  &nbsp;
                  <Table2XLSX />
                </Form.Group>
              </div>
              <div style={{ flex: 0.5, justifyContent: "center" }}>
                <div>
                  {`Payment : ${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(payment)}`}
                  &nbsp; &nbsp;
                  {`Invoice : ${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(invoice)}`}
                  &nbsp; &nbsp;
                  {`Balance : ${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(invoice - payment)}`}
                </div>
              </div>
            </div>
          )}

          {radio === "D" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifuContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Date Between:</Form.ControlLabel>
                    <DateRangePicker
                      cleanable={false}
                      value={selectedDateRange}
                      onChange={setselectedDateRange}
                    />
                    &nbsp;
                    <IconButton
                      onClick={handleSearchforClientByDate}
                      icon={<SearchIcon />}
                    >
                      Search
                    </IconButton>
                    <Button
                      color="green"
                      appearance="subtle"
                      onClick={handleExport1}
                    >
                      <ExportIcon style={{ margin: "5px" }} /> Export
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "A" && (
          <Table striped bordered hover>
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.ClientName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Note}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.ClientName,
                          Date: a.Date,
                          Amount: a.Amount,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "D" && (
          <Table striped bordered hover>
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList2.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.ClientName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Note}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ViewClients;
