import React, { useState } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  Stack,
  Whisper,
  Tooltip,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";
import * as XLSX from "xlsx";
import sendEmail from "../component/sendEmail";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Table2XLSX, Table2XLSXNew } from "../functions";
const ViewSuppliers = () => {
  const [radio, setRadio] = useState("A");

  const [selectedNameID, setselectedNameID] = useState("");
  const [credit, setCredit] = useState(0);
  const [debit, setDebit] = useState(0);
  const [SupplierList, setSupplierList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [GST, setGST] = useState([]);
  const toaster = useToaster();
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [selectedSite, setselectedSite] = useState("");

  const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  const column = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name" },
      { field: "Date", title: "Date" },
      { field: "Credit", title: "Credit" },
      { field: "Debit", title: "Debit" },
      { field: "GST", title: "GST" },
      { field: "Amount", title: "Amount" },
      { field: "Sitename", title: "Sitename" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );

  const column2 = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name" },
      { field: "Date", title: "Date" },
      { field: "Type", title: "Type" },
      { field: "Amount", title: "Amount" },
      { field: "GST", title: "GST" },
      { field: "Notes", title: "Notes" },
    ],
    []
  );
  const tooltip = (
    <Tooltip placement="right">
      {`GST Received : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit)}`}
      <br />
      {`GST Paid : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Debit + debit)}`}
      <br />
      {`Balance : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit - GST.Debit)}  Total : ${Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2,
      }).format(GST.Credit + credit - GST.Debit - debit)}`}
    </Tooltip>
  );
  const getSupplierList = () => {
    setSupplierList([]);
    axios
      .get("https://api.laxmaninterior.in/GetSuppliers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setSupplierList((a) => {
              return [
                ...a,
                { id: r.SrNo, value: r.SupplierName, label: r.SupplierName },
              ];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setRadio(e);
    if (e === "A") {
      getSupplierList();
    }
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getSupplierList();
      getSitesList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  const handleDelete = (
    id,
    data = { Name: "", Date: "", Debit: "", Credit: "" }
  ) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    let text;
    //confirmAlert(options);
    console.log(data.Debit);
    console.log(data.Credit);
    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Payment on ${
        data.Date
      } for ${data.Debit === "0" ? "Credit" : "Debit"} Amount ${
        data.Debit === "0" ? data.Credit : data.Debit
      }?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromSupplier.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Payment on ${
                      data.Date
                    } for Amount Amount ${
                      data.Debit === "0" ? data.Credit : data.Debit
                    } ?`,
                    `Deleted from Supplier(${data.Name}) Transaction`,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Supplier(${data.Name}) Transaction`,
                    "Error in Deleting from Supplier Transaction"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Supplier(${data.Name}) Transaction`,
                  "Error in Deleting from Supplier Transaction"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearchforSupplier = () => {
    setdataList([]);
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { SupplierID: selectedNameID };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
          setGST(data1.GST);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTrasactionsBySite.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchBySupplierSite = () => {
    setdataList([]);
    if (selectedSite === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select site
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = { Sitename: selectedSite, SupplierID: selectedNameID };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetTransactionsBySiteSupplier.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList(data1.Data);
          setCredit(data1.Credit);
          setDebit(data1.Debit);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchforSupplierByDate = () => {
    setProcessing2(true);
    setdataList2([]);
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetSupplierTransactionByDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
            setCredit(data1.Credit);
            setDebit(data1.Debit);
          });
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>
          Suppliers Ledger -{radio === "A" ? "Report by Supplier" : ""}
          {radio === "D" ? "Date wise Search" : ""}
          {radio === "E" ? "Site wise Search" : ""}
          {radio === "F" ? "Site/Supplier wise" : ""}
        </h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Report by Supplier</Radio>
            <Radio value="D">Date wise Search</Radio>
            <Radio value="E">Site wise Search</Radio>
            <Radio value="F">Site/Supplier wise</Radio>
          </RadioGroup>
          &nbsp;
          <Table2XLSXNew tableID="TableData" exportFileName="SupplierExport" />
          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={SupplierList}
                  appearance="subtle"
                  placeholder="Select Supplier"
                  width="auto"
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplier}
                  loading={processing}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
                &nbsp;
                {/* <Table2XLSX /> */}
              </Stack>
              <Stack spacing={4}>
                <div>
                  <span style={{ fontWeight: "bold" }}>Credit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Debit : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(debit)}`}
                </div>
                &nbsp; &nbsp;
                <div>
                  <span style={{ fontWeight: "bold" }}>Balance : </span>{" "}
                  {`${Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(credit - debit)}`}
                </div>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={tooltip}
                >
                  <Button>Detail</Button>
                </Whisper>
              </Stack>
            </Stack>
          )}

          {radio === "D" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <DateRangePicker
                  placeholder="Select Date Range"
                  cleanable={false}
                  value={selectedDateRange}
                  onChange={setselectedDateRange}
                />
                &nbsp;
                <IconButton
                  onClick={handleSearchforSupplierByDate}
                  loading={processing2}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
              </Stack>
            </Stack>
          )}
          {radio === "E" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 250 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
                &nbsp; &nbsp;
                {`Balance : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit - debit)}`}
              </Stack>
            </Stack>
          )}
          {radio === "F" && (
            <Stack spacing={10}>
              <Stack spacing={4}>
                <SelectPicker
                  cleanable={false}
                  data={siteList}
                  appearance="default"
                  placeholder="Select Site"
                  style={{ width: 200 }}
                  key="id"
                  sort={() => {
                    return (a, b) => {
                      return compare(a.value, b.value);
                    };
                  }}
                  onSelect={(e, Item) => {
                    // console.log(Item);
                    setselectedSite(e);
                  }}
                  value={selectedSite}
                />

                <SelectPicker
                  data={SupplierList}
                  cleanable={false}
                  appearance="default"
                  placeholder="Select Supplier"
                  style={{ width: 224 }}
                  value={selectedSupplier}
                  onSelect={(e, Item) => {
                    setSelectedSupplier(e);
                    setselectedNameID(Item.id);
                  }}
                />
                <Button
                  color="blue"
                  appearance="default"
                  onClick={handleSearchBySupplierSite}
                >
                  <SearchIcon style={{ margin: "5px" }} /> Search
                </Button>
                <Table2XLSXNew
                  tableID="TableData"
                  exportFileName="SupplierExport"
                />
              </Stack>
              <Stack spacing={4}>
                {`Credit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit)}`}
                &nbsp; &nbsp;
                {`Debit : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(debit)}`}
                &nbsp; &nbsp;
                {`Balance : ${Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(credit - debit)}`}
              </Stack>
            </Stack>
          )}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "A" && (
          <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td style={{ color: "#2d6f03" }}>
                    {a.Credit > 0 &&
                      Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Credit)}
                  </td>
                  <td style={{ color: "#ad1714" }}>
                    {a.Debit > 0 &&
                      Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(a.Debit)}
                  </td>

                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST + a.Debit + a.Credit)}
                  </td>
                  <td>{a.Sitename}</td>
                  <td>{a.Notes}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.SupplierName,
                          Date: a.Date,
                          Debit: a.Debit,
                          Credit: a.Credit,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "D" && (
          <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column2.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList2.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "E" && (
          <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column2.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "F" && (
          <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column2.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.SupplierName}</td>
                  <td>{a.Date}</td>
                  <td>{a.Type}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.GST)}
                  </td>
                  <td>{a.Notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ViewSuppliers;
