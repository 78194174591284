import React, { useState, useEffect } from "react";
import { Button, useToaster, Toggle, Modal, Form, Notification, } from "rsuite";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { NewAPIAddress, ValidateEmail } from "../functions";
import { v4 as uuidv4 } from 'uuid';
import Header from "../component/Header";

const ManageUsers = () => {
    const UID = useSelector((state) => state.nameReducer.user.uid);
    const [User, setUser] = useState("")
  const [pass, setPass] = useState("")
  const [Name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [contact, setContact] = useState("")
  const toaster = useToaster();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [usersList, setUsersList] = useState([]);
  const [pass1, setPass1] = useState();
  const [processing, setProcessing] = useState(false);
  const [column, setColumn] = useState([
    { field: "Name", title: "Name" },
    { field: "Email", title: "Email" },
    { field: "Mobile", title: "Mobile" },
    { field: "Type", title: "Type" },
    { field: "LastLogin", title: "LastLogin" },
    { field: "Enable", title: "Enable" },
    { field: "Manage", title: "Manage" },
  ]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
React.useEffect(() => {
  var isSubscribed = true
  if(isSubscribed)
  GetAllUsers()
  return () => {
    isSubscribed=false
  };
}, [])
  function GetAllUsers() {
    axios
      .get("https://api.laxmaninterior.in/GetAllUserForWebAppList.php", {})
      .then((data) => {
        //console.log(data);
        const result = data.data;
        if (result.status === 1) {
          console.log(result.Data);
          setUsersList(result.Data);
        } else {
          setUsersList([]);
        }
      })
      .catch((e) => console.log(e));
  }

  function handleSwitch(ID, Status) {
if (ID===UID){
    toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Can not disable your own ID.
        </Notification>,
        { placement: "topEnd" }
      );
return
}

    axios
      .post("https://api.laxmaninterior.in/SetEnabled.php", {
        ID,
        Status,
      })
      .then((data) => {
        //console.log(data);
        const result = data.data;
        if (result.status === 1) {
            toaster.push(
                <Notification type="success" header="Success" duration={1000}>
                  Successfully updated
                </Notification>,
                { placement: "topEnd" }
              );
        } else {
            toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  Something went wrong
                </Notification>,
                { placement: "topEnd" }
              );
        }
      })
      .catch((e) => console.log(e));
  }

function handleAdduser(){
    const UID = uuidv4()
    console.log(UID);
    if (Name === "") {
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            Please enter  name
          </Notification>,
          { placement: "topEnd" }
        );
        setProcessing(false);
        return;
      }

      if (email === ""  || !ValidateEmail(email)) {
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            Please enter valid Email Address
          </Notification>,
          { placement: "topEnd" }
        );
        setProcessing(false);
        return;
      }
      if (pass === "") {
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            Please enter Password
          </Notification>,
          { placement: "topEnd" }
        );
        setProcessing(false);
        return;
      }
      axios.post("https://api.laxmaninterior.in/AddUser.php",{Name, Email:email, Contact:contact, Password:pass, UniqueID:UID})
      .then(data=>{
        const result = data.data
        if(result.status===1){
            toaster.push(
                <Notification type="success" header="Success" duration={1000}>
                  {result.Success}
                </Notification>,
                { placement: "topEnd" }
              );
              handleClose()
              GetAllUsers()
        } else{
            toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {result.Error}
                </Notification>,
                { placement: "topEnd" }
              );
        }
      })
      .catch(e=>{
        toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {e}
            </Notification>,
            { placement: "topEnd" }
          );
      })
}

function handleReset(){
handleOpen1()
if (User === "") {
    toaster.push(
      <Notification type="error" header="Error" duration={1000}>
        Something went wrong.
      </Notification>,
      { placement: "topEnd" }
    );
    setProcessing(false);
    return;
  }

if (pass1 === "") {
    toaster.push(
      <Notification type="error" header="Error" duration={1000}>
        Please enter Password
      </Notification>,
      { placement: "topEnd" }
    );
    setProcessing(false);
    return;
  }
  axios.post("https://api.laxmaninterior.in/ResetPassword.php",{ID:User, Password:pass1})
  .then(data=>{
    const result = data.data
    if(result.status===1){
        toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );
          handleClose1()
    } else{
        toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {result.Error}
            </Notification>,
            { placement: "topEnd" }
          );
    }
  })
  .catch(e=>{
    toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          {e}
        </Notification>,
        { placement: "topEnd" }
      );
  })
}


  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Manage Users</h4>
      </Header>
      <div style={{ paddingLeft: 20 }}>
        <Button color="orange" appearance="primary" onClick={handleOpen}>
          Add User
        </Button>
        <Button
          onClick={GetAllUsers}
          style={{ marginLeft: 10 }}
          color="yellow"
          appearance="primary"
        >
          Reload
        </Button>
      </div>
      <div className="InputContainer">
        <>
          <Header>
            <h5 style={{ color: "white", fontSize: 20 }}>{`All Users`}</h5>
          </Header>
          <div className="InputContainer">
            <Table id="data" striped bordered hover>
              <thead>
                <tr>
                  {column.map((a) => (
                    <th key={a.field}>{a.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {usersList.map((a) => (
                  <tr key={a.UniqueID}>
                    <td>{a.Name}</td>
                    <td>{a.Email}</td>
                    <td>{a.Contact}</td>
                    <td>{a.Type}</td>
                    <td>{a.LastLogin}</td>
                    <td>
                      {" "}
                      <Toggle
                      disabled={a.UniqueID===UID}
                        size="lg"
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        defaultChecked={a.Enabled === "1" ? true : false}
                        onChange={(checked, event) => {
                          handleSwitch(a.UniqueID, checked);
                        }}
                      />
                    </td>
                    <td> <Button color="red" appearance="subtle" onClick={()=>{setUser(a.UniqueID)
                    handleOpen1()
                    }}>
        Reset Password
      </Button></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      </div>

      <div>
              <Modal open={open} onClose={handleClose} size="sm">
                <Modal.Header>
                  <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form layout="horizontal">
                    <Form.Group>
                      <Form.ControlLabel>Name</Form.ControlLabel>
                      <Form.Control
                      style={{width:200}}
                        name="Name"
                        value={Name}
                        onChange={(e) => {
                          setName(e);
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Email</Form.ControlLabel>
                      <Form.Control
                      style={{width:200}}
                        name="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e);
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Contact</Form.ControlLabel>
                      <Form.Control
                      style={{width:200}}
                        name="Contact"
                        value={contact}
                        onChange={(e) => {
                          setContact(e);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel>Password</Form.ControlLabel>
                      <Form.Control
                      style={{width:200}}
                        name="Password"
                        value={pass}
                        onChange={(e) => {
                          setPass(e);
                        }}
                      />
                    </Form.Group>



                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleAdduser} appearance="primary">
                    Submit
                  </Button>
                  <Button onClick={handleClose} appearance="subtle">
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>










              <Modal open={open1} onClose={handleClose1} size="sm">
                <Modal.Header>
                  <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form layout="horizontal">
                    
                    <Form.Group>
                      <Form.ControlLabel>Password</Form.ControlLabel>
                      <Form.Control
                      style={{width:200}}
                        name="Password"
                        value={pass1}
                        onChange={(e) => {
                          setPass1(e);
                        }}
                      />
                    </Form.Group>



                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleReset} appearance="primary">
                    Submit
                  </Button>
                  <Button onClick={handleClose1} appearance="subtle">
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>


    </div>
  );
};

export default ManageUsers;
