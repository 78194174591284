// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBObmfLYUgqj7GX6EfQqu37mxepvoGmduM",
  authDomain: "suthar-483f4.firebaseapp.com",
  projectId: "suthar-483f4",
  storageBucket: "suthar-483f4.appspot.com",
  messagingSenderId: "300415043255",
  appId: "1:300415043255:web:ce6210dff51b56fb98af32"
};

  export const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);
  export default firebaseConfig