import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  DatePicker,
  Form,
  SelectPicker,
  Modal,
  Input,
  InputGroup,
  useToaster,
  Notification,
  AutoComplete,
  Stack,
} from "rsuite";
import Header from "../component/Header";
/* import PlusIcon from "@rsuite/icons/Plus"; */
import { Categories, LabourCategories } from "../component/data";
import { useGetRecipient } from "../Hooks/useGetRecipient";
import { v4 as uuidv4 } from "uuid";
import DetailIcon from "@rsuite/icons/Detail";
import Table from "react-bootstrap/Table";
import PeoplesMapIcon from "@rsuite/icons/PeoplesMap";
import SentToUserIcon from "@rsuite/icons/SentToUser";
import PeoplesCostomizeIcon from "@rsuite/icons/PeoplesCostomize";
import MemberIcon from "@rsuite/icons/Member";

import SendIcon from "@rsuite/icons/Send";
import moment from "moment";

// import ViewEditClient from "../component/TableGrid";
import axios from "axios";
import { generateColor } from "../component/generateColor";
import { Table2XLSXNew, sendFCMPushNotification } from "../functions";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const AddNew = () => {
  const displayName = useSelector((state) => state.nameReducer.user.uid);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("");
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [textColor, setTextColor] = useState("");
  const [nameID, setNameID] = useState("");
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [data, setData] = useState([]);
  const [Mode, setMode] = useState("");
  const NewData = useGetRecipient();

  const UID = useSelector((state) => state.nameReducer.user.uid);
  const [amount, setAmount] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [selectedName1, setselectedName1] = useState("");
  const [selectedNameID1, setselectedNameID1] = useState("");
  const [category, setCategory] = useState("");
  const [selectedLabour, setSelectedLabour] = useState("");
  const [selectedDate1, setselectedDate1] = useState(new Date());
  const [selectedDate2, setselectedDate2] = useState(new Date());
  const [selectedDate3, setselectedDate3] = useState(new Date());
  const [laboursList, setLaboursList] = useState([]);
  const [selectedLabourID, setSelectedLabourID] = useState("");
  const [vendor, setVendor] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [billCopy, setBillCopy] = useState("");
  const [sitename, setSitename] = useState("");
  const [films1, setFilms1] = useState([]);

  let i = 0;

  const categories = [
    { key: ++i, label: "Salary", value: "Salary" },
    { key: ++i, label: "Advance", value: "Advance" },
    { key: ++i, label: "Material", value: "Material" },
    { key: ++i, label: "Travel", value: "Travel" },
    { key: ++i, label: "Other", value: "Other" },
  ];
  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const user = useSelector((state) => {
    return state.user;
  });

  const [value, setValue] = React.useState({
    Recipient: "",
    Date: new Date(),
    Category: "Other",
    Amount: "",
    Desc: "",
  });
  const toaster = useToaster();
  const column = [
    { field: "Name", title: "Name" },
    { field: "Date", title: "Date" },
    { field: "Amount", title: "Amount" },
    { field: "Category", title: "Category" },
    { field: "Description", title: "Description" },
    { field: "RecipientType", title: "RecipientType" },
    { field: "SavedBy", title: "SavedBy" },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };

  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const getVendors = () => {
    axios
      .get("https://api.laxmaninterior.in/GetVendorName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          const test = result.Data.map(({ Name }) => Name);
          setVendorList(test);
          // console.log(result.Data)
        }
        if (result.status === 0 || result.status === 2) {
          setVendorList([]);
        }
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetUsersForDropDown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setUsersList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLaboursList = () => {
    setLaboursList([]);
    axios
      .get("https://api.laxmaninterior.in/GetLaboursForWeb.php")
      .then((response) => {
        const data1 = response.data;
        if (data1.status === 1) {
          console.log(data1.Data);
          setLaboursList(data1.Data);
        }
        if (data1.status === 0) {
          setLaboursList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const SaveData = async () => {
    setProcessing(true);
    if (value.Amount === "" || value.Amount === 0) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const SDate = new Date(value.Date);
    let date1 = new Date(
      SDate.getFullYear(),
      SDate.getMonth(),
      SDate.getDate()
    );
    var now = moment(date1);
    //console.log(uuidv4());
    const datatoSend = {
      Name: value.Recipient,
      Date: moment(now).format("YYYY-MM-DD"),
      Amount: value.Amount,
      Color: textColor || "#ffdbc0",
      Desc: `${value.Desc} // ${Mode}`,
      SavedBy: displayName,
      UniqueID: uuidv4(),
      NameID: nameID,
      RecipientType: type,
      Type: "Debit",
      Category: value.Category,
    };
    try {
      console.log(datatoSend);
      axios
        .post("https://api.laxmaninterior.in/AppExpense.php", datatoSend, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          const result = response.data;
          console.log(result);
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Saved successfully
              </Notification>,
              { placement: "topEnd" }
            );
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      clearInput();
    } catch (e) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Error occured: {e.Message}
        </Notification>,
        { placement: "topEnd" }
      );
    } finally {
      setProcessing(false);
    }
  };
  const getTodays = () => {
    axios
      .get("https://api.laxmaninterior.in/GetTodaysTransactionWebApp.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const clientList = data1.Data;
          //var outputData = clientList.map(Object.values);
          setData(clientList);
        }
        if (data1.status === 0) {
        }
        //setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        // setProcessing1(false);
      });
  };
  function clearInput() {
    setValue({
      Recipient: "",
      Date: new Date(),
      Frequency: "",
      Amount: "",
      Desc: "",
    });
    setTextColor("");
    setNameID("");
  }
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getTodays();
      getUsersList();
      getLaboursList();
      getVendors();
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const AddBillerInSQL = () => {
    const UniqueID = uuidv4();
    try {
      const datatoSend = {
        Name: text,
        Color: generateColor(),
        Desc: desc,
        AddedBy: user.uid,
        UniqueID,
      };
      axios
        .post("https://api.laxmaninterior.in/AddBiller.php", datatoSend, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          const result = response.data;
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="Success" duration={1000}>
                Added successfully
              </Notification>,
              { placement: "topEnd" }
            );
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  function handlePayment() {
    if (selectedNameID1 === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate1 === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please Select transaction date.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (amount === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter transaction amount.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (category === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select category.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const UniqueID = uuidv4();
    const datatoSend = {
      Name: selectedName1,
      Date: moment(selectedDate1).format("YYYY-MM-DD"),
      Amount: amount,
      Month: moment(selectedDate1).format("YYYY-MM-DD"),
      Desc: `${value.Desc} // ${Mode}`,
      SavedBy: UID,
      UniqueID,
      NameID: selectedNameID1,
      RecipientType: "Supervisor",
      Type: "Debit",
      Category: category,
    };
    axios
      .post("https://api.laxmaninterior.in/AppExpense.php", datatoSend, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="Success" duration={1000}>
              Updated successfully
            </Notification>,
            { placement: "topEnd" }
          );
          setselectedName1("");
          setselectedNameID1("");
          setAmount(0);
          setCategory("");
          setDesc("");
          handleClose2();
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              Error occured
            </Notification>,
            { placement: "topEnd" }
          );
          handleClose();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const PayBySupervisor = () => {
    const UniqueID = uuidv4();

    try {
      const datatoSend = {
        Name: selectedLabour,
        Date: moment(selectedDate2).format("YYYY-MM-DD"),
        Amount: amount,
        Month: moment(selectedDate2).format("YYYY-MM-DD"),
        Desc: `${value.Desc} // ${Mode}`,
        SavedBy: selectedNameID,
        UniqueID,
        NameID: selectedLabourID,
        RecipientType: "Labour",
        Type: "Debit",
        Category: category,
      };
      axios
        .post("https://api.laxmaninterior.in/AppExpense.php", datatoSend, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          const result = response.data;

          if (result.status === 1) {
            sendFCMPushNotification(
              "New Expense",
              `New payment to : ${selectedLabour}, Amount - ${amount}, Date - ${moment(
                selectedDate2
              ).format("DD-MM-YYYY")}, Type - ${category}`
            );
            toaster.push(
              <Notification type="success" header="Success" duration={1000}>
                Transaction recorded successfully.
              </Notification>,
              { placement: "topEnd" }
            );
            handleClose3();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                Error occured
              </Notification>,
              { placement: "topEnd" }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const materialTransactions = () => {
    const UniqueID = uuidv4();
    const formData = new FormData();
    formData.append("File", billCopy);
    setProcessing(true);
    if (vendor === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate3 === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please Select transaction date.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (amount === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter bill amount.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    try {
      if (billCopy) {
        setProcessing1(true);
        axios
          .post("https://downloads.laxmaninterior.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              setProcessing(true);
              const datatoSend = {
                Name: vendor,
                Date: moment(selectedDate3).format("YYYY-MM-DD"),
                Amount: amount,
                Month: moment(selectedDate3).format("YYYY-MM-DD"),
                Desc: `${value.Desc} - ${Mode} // ===Bill Copy Attached===`,
                SavedBy: selectedNameID,
                UniqueID,
                NameID: vendor,
                RecipientType: "Material",
                Type: "Debit",
                Category: "MaterialBill",
                Bill: result.Filename,
                SiteName: sitename,
              };

              axios
                .post(
                  "https://downloads.laxmaninterior.in/AddMaterialTransaction.php",
                  datatoSend,
                  {
                    headers: { "Content-type": "application/json" },
                  }
                )
                .then((response) => {
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="Success"
                        duration={1000}
                      >
                        Updated successfully
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    setProcessing(false);
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error" duration={1000}>
                        Error occured
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    handleClose4();
                    setProcessing(false);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  setProcessing(false);
                });

              handleClose4();
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        const datatoSend = {
          Name: vendor,
          Date: moment(selectedDate3).format("YYYY-MM-DD"),
          Amount: amount,
          Month: moment(selectedDate3).format("YYYY-MM-DD"),
          Desc: `${value.Desc} - ${Mode}`,
          SavedBy: selectedNameID,
          UniqueID,
          NameID: vendor,
          RecipientType: "Material",
          Type: "Debit",
          Category: "MaterialBill",
          SiteName: sitename,
        };

        axios
          .post(
            "https://downloads.laxmaninterior.in/AddMaterialTransaction.php",
            datatoSend,
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              toaster.push(
                <Notification type="success" header="Success" duration={1000}>
                  Updated successfully
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  Error occured
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing(false);
              handleClose4();
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing(false);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      setProcessing1(false);
    }
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add New</h4>
      </Header>
      <div className="Subbody">
        <div style={{ flexDirection: "column" }}>
          <Stack spacing={4}>
            <SelectPicker
              data={NewData}
              cleanable={false}
              appearance="default"
              placeholder="Select Payee"
              style={{ width: 224 }}
              value={value.Recipient}
              onSelect={(e, Item) => {
                setValue((a) => {
                  return { ...a, Recipient: e };
                });

                setNameID(Item.id);
                setType(Item.Type);
              }}
            />

            <DatePicker
              cleanable={false}
              appearance="default"
              placeholder="Select Date"
              oneTap
              format="dd-MM-yyyy"
              style={{ width: 150 }}
              value={value.Date}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Date: e };
                });
              }}
            />

            <SelectPicker
              searchable={false}
              cleanable={false}
              data={type === "Labour" ? LabourCategories : Categories}
              appearance="default"
              placeholder="Category"
              style={{ width: 120 }}
              value={value.Category}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Category: e };
                });
              }}
            />

            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>₹</InputGroup.Addon>
              <Input
                value={value.Amount}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, Amount: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>

            <Button appearance="link" onClick={handleOpen1}>
              <DetailIcon style={{ margin: "5px" }} /> Add Desc
            </Button>

            <Button
              color="green"
              loading={processing}
              appearance="primary"
              onClick={SaveData}
            >
              <SendIcon style={{ margin: "5px" }} /> Save
            </Button>
          </Stack>
          <hr />
          <Stack spacing={4}>
            <Button color="green" appearance="primary" onClick={handleOpen}>
              <MemberIcon style={{ margin: "5px" }} /> Add Payee
            </Button>
            <Button color="green" appearance="primary" onClick={handleOpen2}>
              <SentToUserIcon style={{ margin: "5px" }} /> Payments to
              Supervisor
            </Button>
            <Button color="green" appearance="primary" onClick={handleOpen3}>
              <PeoplesMapIcon style={{ margin: "5px" }} /> Supervisor Payments
            </Button>
            <Button color="green" appearance="primary" onClick={handleOpen4}>
              <PeoplesCostomizeIcon style={{ margin: "5px" }} /> Material
              Payments
            </Button>
            <Table2XLSXNew
              tableID="TableData"
              exportFileName={`Transaction-${moment().format("DD-MM-YY")}`}
            />
          </Stack>
        </div>
        <div>
          <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
              <Modal.Title>New Payee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                  <Form.HelpText>Required</Form.HelpText>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Descriptions</Form.ControlLabel>
                  <Form.Control
                    rows={5}
                    name="textarea"
                    accepter={Textarea}
                    style={{ resize: "none" }}
                    value={desc}
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={AddBillerInSQL} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* Description: ===================================================== */}
        <div>
          <Modal open={open1} onClose={handleClose1} size="xs">
            <Modal.Header>
              <Modal.Title>New Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form fluid>
                <Form.Group controlId="textarea-9">
                  <Form.ControlLabel>Descriptions</Form.ControlLabel>
                  <Form.Control
                    rows={5}
                    name="textarea"
                    accepter={Textarea}
                    style={{ resize: "none" }}
                    value={value.Desc}
                    onChange={(e) => {
                      setValue((a) => {
                        return { ...a, Desc: e };
                      });
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose1} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose1} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* ============================Payments to Supervisor================================= */}
        <div>
          <Modal open={open2} onClose={handleClose2} size="sm">
            <Modal.Header>
              <Modal.Title>Pay Supervisor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form layout="horizontal">
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedName1}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setselectedName1(e);
                      setselectedNameID1(Item.key);
                    }}
                    value={selectedName1}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Date</Form.ControlLabel>
                  <DatePicker
                    appearance="default"
                    placeholder="Select Date"
                    oneTap
                    format="dd-MM-yyyy"
                    style={{ width: 150 }}
                    value={selectedDate1}
                    onChange={(e) => {
                      setselectedDate1(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Amount</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Category</Form.ControlLabel>
                  <SelectPicker
                    data={categories}
                    appearance="default"
                    placeholder="Select"
                    style={{ width: 220 }}
                    value={category}
                    onChange={(e) => {
                      setCategory(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Desc</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Description"
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handlePayment} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose2} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* ============================Payments by Supervisor========================== */}
        <div>
          <Modal open={open3} onClose={handleClose3} size="sm">
            <Modal.Header>
              <Modal.Title>Paid By Supervisor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form layout="horizontal">
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <SelectPicker
                    data={laboursList}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedLabour}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      console.log(Item);
                      setSelectedLabour(e);
                      setSelectedLabourID(Item.id);
                    }}
                    value={selectedLabour}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Date</Form.ControlLabel>
                  <DatePicker
                    appearance="default"
                    placeholder="Select Date"
                    defaultValue={new Date()}
                    oneTap
                    style={{ width: 150 }}
                    value={selectedDate2}
                    onChange={(e) => {
                      setselectedDate2(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Amount</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Category</Form.ControlLabel>
                  <SelectPicker
                    data={categories}
                    appearance="default"
                    placeholder="Select"
                    style={{ width: 220 }}
                    value={category}
                    onChange={(e) => {
                      setCategory(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Desc</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Description"
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Payment Mode</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={[
                      { id: 1, value: "Cash", label: "Cash" },
                      { id: 2, value: "Canara Bank", label: "Canara Bank" },
                      { id: 2, value: "Bandhan Bank", label: "Bandhan Bank" },
                      { id: 2, value: "BOB Bank", label: "BOB Bank" },
                    ]}
                    appearance="default"
                    placeholder="Select payment mode"
                    style={{ width: 200 }}
                    key="id"
                    value={Mode}
                    onChange={(e) => {
                      setMode(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Paid By</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Default"
                    defaultValue={selectedName}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      /* console.log(Item);
                        console.log(e); */
                      setselectedName(e);
                      setselectedNameID(Item.key);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={PayBySupervisor} appearance="primary">
                Submit
              </Button>
              <Button onClick={handleClose3} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* ============================Payments for material========================== */}
        <div>
          <Modal open={open4} onClose={handleClose4} size="sm">
            <Modal.Header>
              <Modal.Title>Material</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                layout="horizontal"
                method="POST"
                enctype="multipart/form-data"
                onSubmit={materialTransactions}
                fluid
              >
                <Form.Group>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.ControlLabel>Vendor Name</Form.ControlLabel>
                    <AutoComplete
                      data={vendorList}
                      value={vendor}
                      onChange={(e) => setVendor(e)}
                      style={{ width: 250 }}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Date</Form.ControlLabel>
                  <DatePicker
                    cleanable={false}
                    appearance="default"
                    placeholder="Select Date"
                    format="dd-MM-yyyy"
                    oneTap
                    style={{ width: 150 }}
                    value={selectedDate3}
                    onChange={(e) => {
                      setselectedDate3(e);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>Amount</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Desc</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 200 }}
                    name="Description"
                    value={desc}
                    onChange={(e) => {
                      setDesc(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Payment Mode</Form.ControlLabel>
                  <SelectPicker
                    cleanable={false}
                    data={[
                      { id: 1, value: "Cash", label: "Cash" },
                      { id: 2, value: "Canara Bank", label: "Canara Bank" },
                      { id: 2, value: "Bandhan Bank", label: "Bandhan Bank" },
                      { id: 2, value: "BOB Bank", label: "BOB Bank" },
                    ]}
                    appearance="default"
                    placeholder="Select payment mode"
                    style={{ width: 200 }}
                    key="id"
                    value={Mode}
                    onChange={(e) => {
                      setMode(e);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Paid By</Form.ControlLabel>
                  <SelectPicker
                    data={usersList}
                    cleanable={false}
                    appearance="default"
                    placeholder="Select from list"
                    defaultValue={selectedName}
                    style={{ width: 200 }}
                    key="id"
                    sort={() => {
                      return (a, b) => {
                        return compare(a.value, b.value);
                      };
                    }}
                    onSelect={(e, Item) => {
                      setselectedName(e);
                      setselectedNameID(Item.key);
                    }}
                    value={selectedName}
                  />
                </Form.Group>
                <Form.Group></Form.Group>
                <Form.Group>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.ControlLabel>SiteName</Form.ControlLabel>
                    <AutoComplete
                      data={films1}
                      value={sitename}
                      onChange={(e) => setSitename(e)}
                      style={{ width: 200 }}
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>
                    {processing1 ? "Bill Uploading " : "Bill"}
                  </Form.ControlLabel>
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => setBillCopy(e.target.files[0])}
                  ></input>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={materialTransactions}
                appearance="primary"
                disabled={processing || processing1}
                loading={processing || processing1}
              >
                Submit
              </Button>
              <Button
                onClick={handleClose4}
                appearance="subtle"
                disabled={processing || processing1}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <Header>
        <h5 style={{ color: "white", fontSize: 20 }}>Today's Transactions</h5>
      </Header>
      <div className="InputContainer">
        <Table id="TableData" striped bordered hover>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.Name}</td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>{a.Category}</td>
                <td>{a.Description}</td>
                <td>{a.RecipientType}</td>
                <td>{a.SavedBy}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddNew;
