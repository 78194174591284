import React from "react";
import { useRef, useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import "./vendor/bootstrap/css/bootstrap.min.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./vendor/animate/animate.css";
import "./vendor/css-hamburgers/hamburgers.min.css";
import "./vendor/animsition/css/animsition.min.css";
import "./vendor/select2/select2.min.css";
import "./css/util.css";
import "./css/main.css";

import { useToaster, Message } from "rsuite";
/* import { setAuth, setPersist } from "context";
import { useMaterialUIController } from "context"; */

import { SET_USER } from "../redux/type";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import { currentBrowser, OS } from "../../functions";

const LOGIN_URL = "/Login.php";
const OTP_URL = "/VerifyOTP.php";
export default function Login() {
  const dispatch = useDispatch();
  const toaster = useToaster();
  const [passVerified, setPassVerified] = React.useState(false);

  /* const handleLogin = (e) => {
    e.preventDefault();
    axios.post("Login.php", {
      
    })
    setPassVerified(true);
  }; */
  //console.log(useAuth());
  //const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [otp, setOTP] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [ip, setIP] = useState("");

  useEffect(() => {
    let ismounted = true;
    if (ismounted) userRef.current.focus();
    axios
      .get("https://ip.nf/me.json")
      .then((a) => setIP(ip?.ip))
      .catch((e) => {
        console.log(e);
      });
    return () => {
      ismounted = false;
    };
  }, []);
  useEffect(() => {
    sessionStorage.setItem("persist", true);
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleLogin = async (e) => {
    // console.log(auth);
    e.preventDefault();
    const from = location.state?.from?.pathname || "/";
    if (user && pwd && passVerified && otp) {
      try {
        const response = await axios.post(
          OTP_URL,
          JSON.stringify({
            Email: user,
            Password: pwd,
            OTP: otp,
            Browser: currentBrowser,
            OS: OS,
            IP: ip,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          /* setAuth(dispatch, {
            user: result.Data,
            roles: [5150, 1984, 2001],
            permissions: result?.Permissions || [201],
            accessToken: result.Data.SessionID,
          }); */
          // setUser("");
          // setPwd("");

          //  navigate(from, { replace: true });
          console.log("Enter OTP");
          setErrMsg("Incorrect OTP");

          toaster.push(<Message type="success">Access granted</Message>);
          sessionStorage.setItem("accessToken", result.Data.SessionID);

          dispatch({
            type: SET_USER,
            payload: {
              userDetails: result.Data,
              Name: result.Data.Name,
              Email: result.Data.Email,
              UniqueID: result.Data.UniqueID,
              roles: [5150, 1984, 2001],
              permissions: result?.Permissions || [201],
              accessToken: result.Data.SessionID,
            },
          });
          navigate("/Main");
        }
        if (result.status === 4) {
          toaster.push(
            <Message type="error">
              {`Incorrect OTP, please verify again.`}
            </Message>
          );
          setErrMsg(`Incorrect OTP, please verify again.`);
        }
      } catch (e) {}
    } else {
      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({ Email: user, Password: pwd }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const result = response.data;
        if (
          response &&
          (response["status"] === 201 || response["status"] === 200)
        ) {
          if (result.status === 1) {
            toaster.push(
              <Message type="success">Authenticated successfully</Message>
            );

            setPassVerified(true);
          }
          if (result.status === 0) {
            toaster.push(<Message type="error">{result.Error}</Message>);
            setErrMsg(result.Error);
          }
          if (result.status === 2) {
            setErrMsg("No access, please contact admin.");
            toaster.push(
              <Message type="error">No access, please contact admin.</Message>
            );
          }
          if (result.status === 3) {
            toaster.push(
              <Message type="error">
                Account locked, please contact admin.
              </Message>
            );
            setErrMsg("Account locked, please contact admin.");
          }
          // console.log(JSON.stringify(response?.data));
          //console.log(JSON.stringify(response));

          //navigate("/", { replace: true });
          //console.log(response);
        }
      } catch (err) {
        console.log(err);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      }
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100" style={{ backgroundImage: "" }}>
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form className="login100-form validate-form" onSubmit={handleLogin}>
            <span className="login100-form-title p-b-49">Login</span>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is required"
            >
              <span className="label-input100">Username</span>
              <input
                disabled={passVerified}
                className="input100"
                ref={userRef}
                type="text"
                name="username"
                placeholder="Type your username"
                id="username"
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                autoComplete="off"
                readonly
                disabled={passVerified}
                className="input100"
                type="password"
                name="pass"
                placeholder="Type your password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>
            &nbsp;
            {passVerified && (
              <div
                className="wrap-input100 validate-input m-b-30"
                data-validate="Password is required"
              >
                <span className="label-input100">Security code</span>
                <input
                  className="input100"
                  type="text"
                  name="OTP"
                  placeholder="Type your security code"
                  onChange={(e) => setOTP(e.target.value)}
                  value={otp}
                  required
                />
                <span className="focus-input100" data-symbol="&#xf00a;"></span>
              </div>
            )}
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {/* <div className="persistCheck">
              <input type="checkbox" id="persist" onChange={togglePersist} checked={persist} />
              <label htmlFor="persist">Trust This Device</label>
            </div> */}
            <div className="text-right p-t-8 p-b-31">
              <a href="#">Forgot password?</a>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button className="login100-form-btn">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
