import { useState, useEffect } from "react";
import { db } from "../Config";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import axios from "axios";
export const useGetRecipient = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.laxmaninterior.in/GetAllBillerForWeb.php")
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          setdata(result.Data);
        }
        if (result.status === 0) {
          setdata([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    return () => {};
  }, []);

  return data;
};

export const useGetTodays = () => {
  const [data, setData] = useState([]);


  axios
  .get("https://api.laxmaninterior.in/GetTodaysTransactionWebApp.php")
  .then(function (response) {
    const data1 = response.data;
    if (data1.status === 1) {
      const clientList = data1.Data;
      var outputData = clientList.map(Object.values);
      setData(outputData);
    }
    if (data1.status === 0) {
    }
    //setProcessing1(false);
  })
  .catch(function (error) {
    console.log(error);
   // setProcessing1(false);
  });

  return data;
};

export const useAllTransaction = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "Transactions"));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const Lists = [];
        querySnapshot.forEach((doc) => {
          //Lists.push({id:doc.id, Name:doc.data().Name, Color:doc.data().Color, Date:moment(new Date(doc.data().StartDate.toDate())).format("DD MMM YYYY"), Amount:doc.data().Amount, Desc:doc.data().Desc, Month:doc.data().Month, NameID:doc.data().NameID})

          console.log(doc.id);
        });
        setdata(Lists);
      },
      (error) => {
        // ...
        console.log(error);
      }
    );
    return () => unsubscribe();
  }, []);

  return data;
};

export const useAllusers = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    axios
      .get("https://moneymanagerfirebaseadmin.herokuapp.com/ListofUsers", {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then(async function (response) {
        const data1 = response.data;
        // console.log(data);
        if (response.status !== 200) {
          console.log("Error");
        } else {
          let abc = [];
          data1.forEach((a) => {
            abc.push({
              ID: a.uid,
              Email: a.email,
              Name: a.displayName,
              Pic: a.photoURL,
              Active: `${!a.disabled ? "Y" : "N"}`,
            });
          });
          setdata(abc);
        }
        // console.log(listOfUsers);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});

    return () => {};
  }, []);

  return data;
};
/* 
export const useAllClients = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {

    const unsubscribe=  axios.get("https://api.laxmaninterior.in/GetClients.php")
    .then(function (response) {
      const data1 = response.data;
      //console.log(data1.Data);
      if(data1.status ===1){

        data1.Data.forEach(r=>{
          setdata(a=>{
            return [...a, {id:r.SrNo, value:r.ClientName, label:r.ClientDesc}]
          })
        })
      }
      if(data1.status===0){

      }
//console.log(data);
    })
    .catch(function (error) {
      console.log(error);
    })
    
    return () => {unsubscribe()};
  }, []);

  return data;
}; */

export const useAllUsersData = (def) => {
  const [data, setdata] = useState(def);

  useEffect(() => {
    async function loadall() {
      let newarr = [];
      const users = query(collection(db, "users"));
      const querySnapshot = await getDocs(users);
      querySnapshot.forEach(async (doc) => {
        const q = query(collection(db, "Transactions", doc.id, "Transaction"));
        const queryResult = await getDocs(q);
        queryResult.forEach((doc) => {
          newarr.push({
            ...doc.data(),
            NewDate: moment(new Date(doc.data().StartDate.toDate())).format(
              "DD MMM YYYY"
            ),
          });
        });
      });
      setdata(newarr);
    }

    loadall();

    return () => {};
  }, []);

  return data;
};
