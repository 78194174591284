import React, { useCallback, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Form,
  InputGroup,
  AutoComplete,
  Modal,
  SelectPicker,
  useToaster,
  Notification,
  IconButton,
  Toggle,
  Stack,
} from "rsuite";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import TrashIcon from "@rsuite/icons/Trash";
import { v4 as uuidv4 } from "uuid";
import ReloadIcon from "@rsuite/icons/Reload";
import axios from "axios";
import AddOutlineIcon from "@rsuite/icons/AddOutline";

import DetailIcon from "@rsuite/icons/Detail";
import * as XLSX from "xlsx";
/* import { BorderColor } from "@mui/icons-material"; */
import moment from "moment";
import { Table2XLSX, Table2XLSXNew } from "../functions";
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const AddSupplierTransaction = () => {
  const [SupplierList, setSupplierList] = useState([]);

  /* const [open, setOpen] = React.useState(false); */
  const [open1, setOpen1] = React.useState(false);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState("md");
  const toggle = useCallback((props) => {
    setLoading(true);

    setTimeout(() => {
      setChecked((checked) => !checked);
      setLoading(false);
    }, 1000);

    return (
      <Toggle
        loading={loading}
        checked={checked}
        onChange={toggle}
        {...props}
      />
    );
  }, []);

  const [value, setValue] = React.useState({
    SupplierName: "",
    SupplierID: "",
    Date: new Date(),
    Type: "",
    Amount: "",
    GST: "",
    Note: "",
  });
  const toaster = useToaster();
  /* const [column, setColumn] = useState([
    { field: "SupplierName", title: "Supplier Name" },
    { field: "Date", title: "Date" },
    { field: "Amount", title: "Amount" },
    { field: "GST", title: "GST" },
    { field: "Type", title: "Type" },
    { field: "Note", title: "Note" },
  ]); */

  const column = React.useMemo(
    () => [
      { field: "SupplierName", title: "Supplier Name" },
      { field: "Date", title: "Date" },
      { field: "Amount", title: "Amount" },
      { field: "GST", title: "GST" },
      { field: "Type", title: "Type" },
      { field: "Note", title: "Note" },
    ],
    []
  );

  const [usersList, setUsersList] = useState([]);
  const [selectedName, setselectedName] = useState("");
  const [selectedNameID, setselectedNameID] = useState("");
  const [billCopy, setBillCopy] = useState("");
  const [sitename, setSitename] = useState("");
  const [films1, setFilms1] = useState([]);

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const getList = () => {
    //RetriveSiteName()
    axios
      .get("https://api.laxmaninterior.in/GetSiteName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          //console.log(result.Data.map(({SiteName})=>SiteName))
          const test = result.Data.map(({ SiteName }) => SiteName);
          setFilms1(test);
          // console.log(result.Data)
        }
        if (result.status === 0) {
          setFilms1([]);
        }
        //console.log(result);
      })
      .catch((a) => {
        console.log(a);
      });
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetUsersForDropDown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setUsersList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getUsersList();
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  /* const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }; */
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  /* 
  const handleExport = () => {
    setProcessing1(true);
    axios
      .get("https://api.laxmaninterior.in/GetAllSupplierTransactionExport.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "Supplier");
          XLSX.writeFile(wb, "SupplierTransactions.xlsx");
        }
        if (data1.status === 0) {
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
    setProcessing1(false);
  }; */

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleRefresh = () => {
    getSupplierList();
    axios
      .get("https://api.laxmaninterior.in/GetAllSupplierTransaction.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setDataList(data1.Data);
        }
        if (data1.status === 0) {
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
    setProcessing(false);
  };

  const getSupplierList = () => {
    setSupplierList([]);
    axios
      .get("https://api.laxmaninterior.in/GetSuppliers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setSupplierList((a) => {
              return [
                ...a,
                { id: r.SrNo, value: r.SupplierName, label: r.SupplierName },
              ];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const SaveData = () => {
    const formData = new FormData();
    formData.append("File", billCopy);
    setProcessing(true);

    if (value.SupplierName === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Supplier name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Type === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter transaction type
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (value.Amount === "" || value.Amount === 0) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter valid amount
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Date === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter date
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    if (checked && billCopy === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select bill copy
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    try {
      if (checked) {
        setProcessing1(true);
        axios
          .post("https://downloads.laxmaninterior.in/AddBill.php", formData)
          .then((response) => {
            const result = response.data;
            setProcessing1(false);
            if (result.status === 1) {
              console.log(value);
              axios
                .post(
                  "https://api.laxmaninterior.in/AddSupplierTransactions.php",
                  {
                    ...value,
                    SiteName: sitename,
                    Bill: result.Filename,
                    PaidBy: selectedNameID,
                  }
                )
                .then((response) => {
                  const result = response.data;
                  console.log(result);
                  if (result.status === 1) {
                    toaster.push(
                      <Notification
                        type="success"
                        header="success"
                        duration={1000}
                      >
                        {result.Success}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                    handleRefresh();
                  }
                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error" duration={1000}>
                        {result.error}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                  }

                  setProcessing(false);
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {`Error occured - ${result.Error}`}
                </Notification>,
                { placement: "topEnd" }
              );
              setProcessing1(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setProcessing1(false);
          });
      } else {
        setProcessing(true);
        axios
          .post("https://api.laxmaninterior.in/AddSupplierTransactions.php", {
            ...value,
            SiteName: sitename,
            PaidBy: selectedNameID,
            Date: moment(value.Date).format("YYYY-MM-DD"),
          })
          .then((response) => {
            const result = response.data;
            console.log(result);
            if (result.status === 1) {
              toaster.push(
                <Notification type="success" header="success" duration={1000}>
                  {result.Success}
                </Notification>,
                { placement: "topEnd" }
              );
              handleRefresh();
            }
            if (result.status === 0) {
              toaster.push(
                <Notification type="error" header="Error" duration={1000}>
                  {result.error}
                </Notification>,
                { placement: "topEnd" }
              );
            }

            setProcessing(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProcessing(false);
      setProcessing1(false);
    }
  };

  function clearInput() {
    setValue({
      SupplierName: "",
      SupplierID: "",
      Date: new Date(),
      Type: "",
      Amount: "",
      Note: "",
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add New</h4>
      </Header>
      <div className="Subbody">
        <div style={{ flexDirection: "column" }}>
          <Stack spacing={4}>
            <SelectPicker
              data={SupplierList}
              cleanable={false}
              appearance="default"
              placeholder="Select Supplier"
              style={{ width: 224 }}
              value={value.value}
              onSelect={(e, Item) => {
                setValue((a) => {
                  return {
                    ...a,
                    SupplierName: Item.value,
                    SupplierID: Item.id,
                  };
                });
              }}
            />
            <DatePicker
              oneTap
              cleanable={false}
              appearance="default"
              format="dd-MM-yyyy"
              placeholder="Select Date"
              style={{ width: 150 }}
              value={value.Date}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Date: e };
                });
              }}
            />
            <SelectPicker
              cleanable={false}
              data={[
                { id: 1, value: "Credit", label: "Credit" },
                { id: 2, value: "Debit", label: "Debit" },
              ]}
              searchable={false}
              appearance="subtle"
              placeholder="Type"
              style={{
                width: 120,
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#eee",
                outline: "none",
              }}
              value={value.Type}
              onChange={(e) => {
                setValue((a) => {
                  return { ...a, Type: e };
                });
              }}
            />
            <InputGroup style={{ width: "250px" }}>
              <InputGroup.Addon>(₹)</InputGroup.Addon>
              <Input
                value={value.Amount}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, Amount: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
            <InputGroup style={{ width: "200px" }}>
              <InputGroup.Addon>GST (₹)</InputGroup.Addon>
              <Input
                value={value.GST}
                onChange={(e) => {
                  setValue((a) => {
                    return { ...a, GST: e };
                  });
                }}
              />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>

            {/* Info: ===================================================== */}
          </Stack>
          <hr></hr>
          <Stack spacing={6}>
            <SelectPicker
              data={usersList}
              appearance="default"
              cleanable={false}
              placeholder="Select from list"
              defaultValue={selectedName}
              style={{ width: 200 }}
              key="id"
              sort={() => {
                return (a, b) => {
                  return compare(a.value, b.value);
                };
              }}
              onSelect={(e, Item) => {
                setselectedName(e);
                setselectedNameID(Item.key);
              }}
              value={selectedName}
            />
            <AutoComplete
              data={films1}
              value={sitename}
              onChange={(e) => setSitename(e)}
              style={{ width: 200 }}
            />
            <Toggle
              checked={checked}
              checkedChildren="Bill"
              unCheckedChildren="No Billl"
              onChange={(e) => {
                setChecked(e);
                console.log(e);
              }}
              size={size}
            />
            {checked && (
              <input
                type="file"
                name="file"
                onChange={(e) => setBillCopy(e.target.files[0])}
              ></input>
            )}
            <Button appearance="link" onClick={handleOpen1}>
              <DetailIcon style={{ margin: "5px" }} /> Note
            </Button>
            {!processing1 && (
              <IconButton
                color="green"
                disabled={processing1}
                appearance="primary"
                onClick={SaveData}
                {...(processing1 ? "loading" : null)}
                icon={<AddOutlineIcon />}
              >
                Add
              </IconButton>
            )}
            &nbsp;
            <Button color="green" appearance="primary" onClick={handleRefresh}>
              <ReloadIcon style={{ margin: "5px" }} />
            </Button>
            &nbsp;
            <Table2XLSXNew
              tableID="TableData"
              exportFileName="Supplier_Details"
            />
          </Stack>
        </div>
        <Modal open={open1} onClose={handleClose1} size="xs">
          <Modal.Header>
            <Modal.Title>New Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group controlId="textarea-9">
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  rows={5}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                  value={value.Note}
                  onChange={(e) => {
                    setValue((a) => {
                      return { ...a, Note: e };
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose1} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose1} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Header>
        <h5 style={{ color: "white", fontSize: 20 }}>
          {`List of Transactions (${dataList.length}) - ${moment().format(
            "MMM-YYYY"
          )} `}{" "}
        </h5>
      </Header>
      <div className="InputContainer">
        <Table striped bordered hover id="TableData">
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.SupplierName}</td>
                <td>{a.Date}</td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.Amount)}
                </td>
                <td>
                  {Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  }).format(a.GST)}
                </td>
                <td>{a.Type}</td>
                <td>{a.Notes}</td>
                <td width={60}>
                  <IconButton
                    disabled
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() => {}}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddSupplierTransaction;
