import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../component/Header";
import {
  Button,
  DatePicker,
  Form,
  SelectPicker,
  RadioGroup,
  Radio,
  DateRangePicker,
} from "rsuite";
import Table from "react-bootstrap/Table";

import * as XLSX from "xlsx";
import ConversionIcon from "@rsuite/icons/Conversion";
import { Month, Year } from "../component/data";
import moment from "moment";
import ReloadIcon from "@rsuite/icons/Reload";
import DashboardIcon from "@rsuite/icons/Dashboard";
import SettingHorizontalIcon from "@rsuite/icons/SettingHorizontal";
import RateIcon from "@rsuite/icons/Rate";
import TableColumnIcon from "@rsuite/icons/TableColumn";
import AdvancedAnalyticsIcon from "@rsuite/icons/AdvancedAnalytics";
import ReviewIcon from "@rsuite/icons/Review";
import SiteFillIcon from "@rsuite/icons/SiteFill";
import UserChangeIcon from "@rsuite/icons/UserChange";
import DetailIcon from "@rsuite/icons/Detail";
import NumbersIcon from "@rsuite/icons/Numbers";
import ProjectIcon from "@rsuite/icons/Project";
import BranchIcon from "@rsuite/icons/Branch";
import axios from "axios";
const Dashboard = () => {
  const [processing2, setProcessing2] = useState(false);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  //const data = useGetTodays();
  const [view, setView] = React.useState("Cards");
  const [viewBy, setViewBy] = React.useState("Year");
  const [monthType, setMonthType] = useState("Current Month");
  const [yearType, setYearType] = useState("Current Financial Year");

  function handleExport() {
    setProcessing2(true);
    const table = document.getElementById("TableData");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "ListAll.xlsx");
    setProcessing2(false);
  }
  const column = useMemo(()=>[
    { field: "Type", title: "Type" },
    { field: "Name", title: "Name" },
    { field: "Amount", title: "Amount" },
    { field: "Amount1", title: "Amount1" },
    { field: "Amount2", title: "Amount2" },
  ]);

  const handleRefresh = (FirstDay, LastDay) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .post("https://api.laxmaninterior.in/GetDashboards.php", {
        FirstDay,
        LastDay,
      }, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    } )
      .then(function (response) {
        const result = response.data;
        if (result.status === 1) {
          setData(result);
        }
        if (result.status === 0) {
          setData({});
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      setData1([]);
      axios
      .post("https://api.laxmaninterior.in/GetAllTransactions.php", {
        FirstDay,
        LastDay,
      })
      .then(function (response) {
        const result = response.data;
        if (result.status === 1) {
          setData1(result.Data);
        }
        if (result.status === 0) {
          setData1([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const reload = () => {
    var TodaysDate = moment(new Date()).format("YYYY-MM-DD");
    var firstDay = new Date();
    var lastDay = new Date();

    //console.log(TodaysDate);
    //=======Current Month===================

    if (viewBy === "Month") {
      let date = new Date(TodaysDate);
      if (monthType === "Current Month") {
        firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(firstDay);
        console.log(lastDay);
      }

      //=======Last Month===================
      if (monthType === "Last Month") {
        firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        console.log(firstDay);
        console.log(lastDay);
      }
      //========================Custom Month==========================
      if (monthType === "Custom") {
        TodaysDate = moment(selectedDate).format("YYYY-MM-DD");
        date = new Date(TodaysDate);
        firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(firstDay);
        console.log(lastDay);
      }
    }

    let d = new Date();
    let currentYear = d.getFullYear();
    if (viewBy === "Year") {
      //================================CurrentYear =======================
      if (yearType === "Current Year") {
        firstDay = new Date(currentYear, 0, 1);
        lastDay = new Date(currentYear, 11, 31);
        console.log(firstDay);
        console.log(lastDay);
      }

      //====================Last Year============================
      if (yearType === "Current Year") {
        firstDay = new Date(currentYear - 1, 0, 1);
        lastDay = new Date(currentYear - 1, 11, 31);
        console.log(firstDay);
        console.log(lastDay);
      }

      //===========================Current Financial Year=====================
      let currentMonth = d.getMonth();
      if (yearType === "Current Financial Year") {
        if (currentMonth < 4) {
          firstDay = new Date(currentYear - 1, 3, 1);
          lastDay = new Date(currentYear, 2, 31);
        } else {
          firstDay = new Date(currentYear, 3, 1);
          lastDay = new Date(currentYear + 1, 2, 31);
        }
        console.log(firstDay);
        console.log(lastDay);
      }

      //===========================Last Financial Year=====================
      if (yearType === "Last Financial Year") {
        if (currentMonth < 4) {
          firstDay = new Date(currentYear - 2, 3, 1);
          lastDay = new Date(currentYear - 1, 2, 31);
        } else {
          firstDay = new Date(currentYear - 1, 3, 1);
          lastDay = new Date(currentYear, 2, 31);
        }
        console.log(firstDay);
        console.log(lastDay);
      }

      //===========================Custom Year=====================
      if (yearType === "Custom") {
        firstDay = selectedDateRange[0]
        lastDay = selectedDateRange[1]
        console.log(firstDay);
        console.log(lastDay);
      }
    }

    handleRefresh(firstDay, lastDay);
  };
React.useEffect(() => {
  let isSubscribed = true
  if(isSubscribed)
  reload()
  return ()=>{
    isSubscribed=false
  }
}, [])

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Dashboard</h4>
      </Header>
      <div className="Subbody">
        <Form layout="inline">
        <Form.Group>
            <RadioGroup
              inline
              name="radio-name"
              value={view}
              onChange={setView}
            >
              <Radio value="Cards">Cards</Radio>
              <Radio value="List">List</Radio>
            </RadioGroup>
          </Form.Group>
          <Form.Group>
            <RadioGroup
              inline
              name="radio-name"
              value={viewBy}
              onChange={setViewBy}
            >
              <Radio value="Month">Month</Radio>
              <Radio value="Year">Year</Radio>
            </RadioGroup>
          </Form.Group>
          {viewBy === "Month" && (
            <>
              <Form.Group>
                <Form.ControlLabel>View By</Form.ControlLabel>
                <SelectPicker
                  data={Month}
                  appearance="default"
                  placeholder="Month"
                  style={{ width: 220 }}
                  value={monthType}
                  onChange={(e) => {
                    setMonthType(e);
                    // console.log(e);
                  }}
                />
              </Form.Group>
              {monthType === "Custom" && (
                <Form.Group>
                  <Form.ControlLabel>Month</Form.ControlLabel>
                  <DatePicker
                    oneTap
                    format="MMM-yyyy"
                    ranges={[]}
                    onSelect={(e) => setselectedDate(e)}
                    style={{ width: 200 }}
                  />
                </Form.Group>
              )}
            </>
          )}
          {viewBy === "Year" && (
            <>
              <Form.Group>
                <Form.ControlLabel>View for</Form.ControlLabel>
                <SelectPicker
                  data={Year}
                  appearance="default"
                  placeholder="Year"
                  style={{ width: 250 }}
                  value={yearType}
                  onChange={(e) => setYearType(e)}
                />
              </Form.Group>
              {yearType === "Custom" && (
                <Form.Group>
                  <Form.ControlLabel>Date Between:</Form.ControlLabel>

                  <DateRangePicker
                    value={selectedDateRange}
                    onChange={setselectedDateRange}
                  />
                </Form.Group>
              )}
            </>
          )}

          <Button
            color="green"
            loading={processing}
            appearance="primary"
            onClick={reload}
          >
            Update
          </Button>
          { view==="List" && 
            <Button
            color="green"
            appearance="subtle"
            onClick={handleExport}
            loading={processing2}
          >
            Export
          </Button>
          }
        </Form>
      </div>
      {view==="Cards" ? (<div className="InputContainer">
        <div className="Card-Container">
          <div className="Cards" style={{ backgroundColor: "#fff5c7" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Payee</p>
              <p style={{ fontSize: "1rem" }}>{data.Count_Payee}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProjectIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards" style={{ backgroundColor: "#aed6be" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Clients</p>
              <p style={{ fontSize: "1rem" }}>{data.Count_Client}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConversionIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards" style={{ backgroundColor: "#aacfec" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Supplier</p>
              <p style={{ fontSize: "1rem" }}>{data.Count_Supplier}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BranchIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards" style={{ backgroundColor: "#bd95ac" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Labours</p>
              <p style={{ fontSize: "1rem" }}>{data.Count_Labour}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UserChangeIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards" style={{ backgroundColor: "#b1a5b3" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Transactions</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Transaction_Amount)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DetailIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards">
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Payments by Clients</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Payment_Client)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DashboardIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>

          <div className="Cards" style={{ backgroundColor: "#bbd9f0" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Invoices to Clients</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Invoice_Client)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AdvancedAnalyticsIcon
                style={{ margin: "5px", fontSize: "2rem" }}
              />
            </div>
          </div>

          <div className="Cards">
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Balance for Clients</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Balance_Client)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SettingHorizontalIcon
                style={{ margin: "5px", fontSize: "2rem" }}
              />
            </div>
          </div>
          <div className="Cards">
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Paid to Supplier</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Debit_Supplier)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SiteFillIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards" style={{ backgroundColor: "#ffd9b2" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Credit from Supplier</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Credit_Supplier)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TableColumnIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards">
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Balance for Supplier</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Balance_Supplier)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReviewIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards" style={{ backgroundColor: "#e5cec1" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Attendance</p>
              <p style={{ fontSize: "1rem" }}>{data.Attendance}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RateIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards" style={{ backgroundColor: "#a7b7d6" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Salary</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Salary_Due)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DashboardIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards" style={{ backgroundColor: "#85aa75" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Salary Paid</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Salary_Paid)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NumbersIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
          <div className="Cards" style={{ backgroundColor: "#aa9183" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}>Balance</p>
              <p style={{ fontSize: "1rem" }}>
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 2,
                }).format(data.Salary_Balance)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AdvancedAnalyticsIcon
                style={{ margin: "5px", fontSize: "2rem" }}
              />
            </div>
          </div>
          <div className="Cards">
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p style={{ fontSize: "1rem" }}></p>
              <p style={{ fontSize: "1rem" }}></p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReloadIcon style={{ margin: "5px", fontSize: "2rem" }} />
            </div>
          </div>
        </div>
      </div>) : (
        <div className="InputContainer">
        
          <Table striped bordered hover ID="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data1.map((a) => (
                <tr key={a.Name}>
                  <td>{a.Type}</td>
                  <td>{a.Name}</td>

                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td>{Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount1)}</td>
                  <td>{Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount2)}</td>
                  
                  
                </tr>
              ))}
            </tbody>
          </Table>
        
        
      </div>
      )}
    </div>
  );
};

export default Dashboard;
