import React, { useState } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  useToaster,
  Notification,
  Tooltip,
  Whisper,
  IconButton,
} from "rsuite";
/* import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit"; */
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import ReloadIcon from "@rsuite/icons/Reload";
import * as XLSX from "xlsx";
import ExportIcon from "@rsuite/icons/Export";
import axios from "axios";
import { Table2XLSX } from "../functions";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const Clients = () => {
  const [data, setData] = useState([]);
  /*   const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false); */
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const [clientList, setClientList] = useState([]);
  const column = React.useMemo(
    () => [
      { field: "ClientName", title: "Client Name" },
      { field: "Desc", title: "Notes" },
      /*     { field: "Edit", title: "Edit" },
    { field: "Delete", title: "Delete" }, */
    ],
    []
  );
  const [value, setValue] = React.useState({
    ClientName: "",
    Desc: "",
  });
  const toaster = useToaster();

  /* const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  }; */

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  /* const EditModal = () => {
    return (
      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title>New Payee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control name="name" />
                <Form.HelpText>Required</Form.HelpText>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  rows={5}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }; */

  function handleExport() {
    setProcessing2(true);

    axios
      .get("https://api.laxmaninterior.in/GetClientsForExport.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data1.Data);
          XLSX.utils.book_append_sheet(wb, ws, "ClientList");
          XLSX.writeFile(wb, "ClientList.xlsx");
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  }

  const handleRefresh = () => {
    setProcessing1(true);

    axios
      .get("https://api.laxmaninterior.in/GetClients.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setClientList(data1.Data);
          var outputData = clientList.map(Object.values);
          setData(outputData);
        }
        if (data1.status === 0) {
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  };

  const SaveData = async () => {
    setProcessing(true);

    if (value.ClientName === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Client Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.Desc === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter atleast small Description
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const data = {
      ClientName: value.ClientName,
      Desc: value.Desc,
    };
    axios
      .post("https://api.laxmaninterior.in/AddClient.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );

          clearInput();
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${value.ClientName} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  function clearInput() {
    setValue({
      ClientName: "",
      Desc: "",
    });
  }

  /* function handleEdit(ID, Name, Desc) {
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title>New Payee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control name="name" value={Name} />
                <Form.HelpText>Required</Form.HelpText>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Descriptions</Form.ControlLabel>
                <Form.Control
                  value={Desc}
                  rows={5}
                  name="textarea"
                  accepter={Textarea}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  function handleDelete(a) {
    
  } */

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add / List Clients</h4>
      </Header>
      <div className="Subbody">
        <Form layout="inline">
          <Form.Group>
            <Form.ControlLabel>Client</Form.ControlLabel>
            <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
              <Input
                style={{ width: 300 }}
                placeholder="Enter party / client name"
                value={value.ClientName}
                onChange={(e) =>
                  setValue((a) => {
                    return { ...a, ClientName: e };
                  })
                }
              />
            </Whisper>
          </Form.Group>
          <Form.Group controlId="textarea-6">
            <Form.ControlLabel>Additional</Form.ControlLabel>
            <Form.Control
              name="textarea"
              rows={1}
              accepter={Textarea}
              value={value.Desc}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, Desc: e };
                })
              }
            />
          </Form.Group>
          <IconButton
            color="green"
            appearance="primary"
            onClick={SaveData}
            loading={processing}
            icon={<AddOutlineIcon />}
          >
            Add
          </IconButton>
          &nbsp;
          <Button
            color="green"
            appearance="primary"
            onClick={handleRefresh}
            loading={processing1}
          >
            <ReloadIcon style={{ margin: "5px" }} /> Refresh
          </Button>
          &nbsp;
          <Table2XLSX />
          {/* <div>
            <Modal open={open} onClose={handleClose} size="xs">
              <Modal.Header>
                <Modal.Title>Add Client</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form fluid>
                  <Form.Group>
                    <Form.ControlLabel>Name</Form.ControlLabel>
                    <Form.Control name="name" />
                    <Form.HelpText>Required</Form.HelpText>
                  </Form.Group>

                  <Form.Group>
                    <Form.ControlLabel>Descriptions</Form.ControlLabel>
                    <Form.Control
                      rows={5}
                      name="textarea"
                      accepter={Textarea}
                      style={{ resize: "none" }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} appearance="primary">
                  Submit
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div> */}
          {/* Description: ===================================================== */}
          {/* <div>
            <Modal open={open1} onClose={handleClose1} size="xs">
              <Modal.Header>
                <Modal.Title>New Description</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form fluid>
                  <Form.Group controlId="textarea-9">
                    <Form.ControlLabel>Descriptions</Form.ControlLabel>
                    <Form.Control
                      rows={5}
                      name="textarea"
                      accepter={Textarea}
                      style={{ resize: "none" }}
                      value={value.Desc}
                      onChange={(e) => {
                        setValue((a) => {
                          return { ...a, Desc: e };
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose1} appearance="primary">
                  Submit
                </Button>
                <Button onClick={handleClose1} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div> */}
          {/* Info: ===================================================== */}
        </Form>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`List of Clients (${data.length})`}</h5>
      </Header>
      <div className="InputContainer">
        <Table>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {clientList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.ClientName}</td>
                <td>{a.ClientDesc}</td>
                {/* <td width={60}>
                  <IconButton
                    icon={<EditIcon />}
                    color="blue"
                    appearance="primary"
                    circle
                    onClick={() => {
                      handleOpen();
                      handleEdit(a.SrNo, a.ClientName, a.ClientDesc);
                    }}
                  />
                </td>
                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() => handleDelete(a.SrNo)}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Clients;
