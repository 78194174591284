import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  useToaster,
  Notification,
  IconButton,
  DatePicker,
  Modal,
  InputGroup,
  Input,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";
import * as XLSX from "xlsx";
import { AdminID, firstDay } from "../Constants";
import ExportIcon from "@rsuite/icons/Export";
import { GetLastDay, Table2XLSX } from "../functions";
const GetSalary = () => {
  const [radio, setRadio] = useState("A");
  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedNameID, setselectedNameID] = useState("");
  const [LabourList, setLabourList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  /* const [processing2, setProcessing2] = useState(false); */
  const [selectedName, setselectedName] = useState("");
  const [datalist1, setdataList1] = useState([]);
  const [datalist2, setdataList2] = useState([]);
  const [datalist3, setdataList3] = useState([]);
  const [salary, setSalary] = useState({});
  const [balance, setBalance] = useState(0);
  const toaster = useToaster();
  const [selectedLabour, setSelectedLabour] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [count, setCount] = useState({});
  const [radio1, setRadio1] = useState("Payments");
  const [sitename, setSitename] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [travel, setTravel] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    if (selectedLabour === "" || selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select Labour first.
        </Notification>,
        { placement: "topEnd" }
      );
      return false;
    }
    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select month.
        </Notification>,
        { placement: "topEnd" }
      );
      return false;
    }
    setOpen(true);
  };

  const [dataforSelectedMonth, setDataforSelectedMonth] = useState({
    AttendanceSelectedMonth: 0,
    SalarySelectedMonth: 0,
    BalanceSelectedMonth: 0,
  });

  const column = React.useMemo(
    () => [
      { field: "Date", title: "Date" },
      { field: "Category", title: "Category" },
      { field: "Amount", title: "Amount" },
      { field: "PaidBy", title: "PaidBy" },
      { field: "Note", title: "Note" },
    ],
    []
  );

  const column2 = React.useMemo(
    () => [
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Attendance", title: "Attendance" },
      { field: "PerDayRate", title: "PerDayRate" },
      { field: "Salary", title: "Salary" },
      { field: "Tea", title: "Tea" },
      { field: "Total", title: "Total" },
    ],
    []
  );
  const column3 = React.useMemo(
    () => [
      { field: "Date", title: "Date" },
      { field: "Attendance", title: "Attendance" },
      { field: "PerDayRate", title: "PerDayRate" },
      { field: "Salary", title: "Salary" },
      { field: "Tea", title: "Tea" },
      { field: "Total", title: "Total" },
    ],
    []
  );

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const handleSupwiseAttendance = () => {
    let datatoSend = {
      SupID: selectedNameID,
      Month: moment(selectedDate).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetAttendanceBySupervisor.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList2(data1.Data);
          setCount(data1.Total);
        }
        if (data1.status === 0) {
          setdataList2([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSitewiseAttendance = () => {
    let datatoSend = { Site: sitename };
    axios
      .post("https://api.laxmaninterior.in/GetAttendanceBySite.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setdataList2(data1.Data);
          setCount(data1.Total);
        }
        if (data1.status === 0) {
          setdataList2([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("https://api.laxmaninterior.in/GetLaboursForWeb.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setLabourList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getUsersList();
      getLabourList();
      getSitesList();
    }
    return () => {};
  }, []);

  const handleRadio1 = (e) => {
    setRadio1(e);
    /* if (e === "A") {
    }
    if (e === "D") {
      getUsersList();
    } */
  };
  const handleRadio = (e) => {
    setRadio(e);
    if (e === "A") {
      getLabourList();
    }
    if (e === "D") {
      getUsersList();
    }
  };

  const handleSups = () => {
    handleSupwiseAttendance();
  };

  const getUsersList = () => {
    setUsersList([]);
    axios
      .get("https://api.laxmaninterior.in/GetSupsList.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setUsersList(data1.Data);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getTravel = () => {
    const datatoSend = {
      Month: moment(selectedDate).format("YYYY-MM-DD"),
      LabourID: selectedNameID,
    };
    axios
      .post("https://api.laxmaninterior.in/GetTravelAllowance.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setTravel(data1.Travel);
          setAmount(data1.Travel);
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAttendanceforLabour = () => {
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    console.log(GetLastDay(selectedDate));
    const datatoSend = {
      LabourID: selectedNameID,
      Month: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetMonthlyDataForLabour.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);
        if (data1.status === 1) {
          setdataList3(data1.Data);
        }
        if (data1.status === 0) {
          setdataList3([]);
        }
      })
      .catch(function (error) {
        console.log(error);
        setdataList3([]);
      });
  };

  const handleSearchforLabour = () => {
    setdataList1([]);
    handleAttendanceforLabour();
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    console.log(GetLastDay(selectedDate));
    const datatoSend = {
      Labour: selectedNameID,
      Date: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };
    //console.log(datatoSend);
    axios
      .post("https://api.laxmaninterior.in/GetSalaryForLabour.php", datatoSend)
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          //setdataList(data1.Data)
          // setdataList1(data1.Data)
          setSalary(data1);
          setDataforSelectedMonth(data1);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });

    axios
      .post("https://api.laxmaninterior.in/GetAllTransactionsForLabour.php", {
        LabourID: selectedNameID,
      })
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          // console.log(data1.Data);
          setdataList1(data1.Data);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });

    axios
      .post("https://api.laxmaninterior.in/GetSalaryBalanceLabour.php", {
        Labour: selectedNameID,
        Date: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
      })
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setBalance(data1);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
    getTravel();
  };

  function handleExport() {
    setProcessing1(true);
    const table = document.getElementById("AllPayment");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "AllPayments.xlsx");
    setProcessing1(false);
  }
  function handleExport1() {
    setProcessing1(true);
    const table = document.getElementById("AllAttendance");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "Attendance.xlsx");
    setProcessing1(false);
  }
  const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAddTravel = () => {
    if (selectedLabour === "" || selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please selecte Labour first
        </Notification>,
        { placement: "topEnd" }
      );
      return false;
    }
    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Please select month.
        </Notification>,
        { placement: "topEnd" }
      );
      return false;
    }

    const datatoSend = {
      LabourID: selectedNameID,
      Date: moment(selectedDate).format("YYYY-MM-DD"),
      Amount: amount,
      LabourName: selectedLabour,
      AddedBy: AdminID,
    };
    try {
      console.log(datatoSend);
      axios
        .post(
          "https://api.laxmaninterior.in/AddTravelAllowance.php",
          datatoSend
        )
        .then((response) => {
          const result = response.data;
          console.log(result);
          if (result.status === 1) {
            toaster.push(
              <Notification type="success" header="success" duration={1000}>
                Saved successfully
              </Notification>,
              { placement: "topEnd" }
            );
            handleClose();
          }
          if (result.status === 0) {
            toaster.push(
              <Notification type="error" header="Error" duration={1000}>
                {result.Error}
              </Notification>,
              { placement: "topEnd" }
            );
            handleClose();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Error occured: {e.Message}
        </Notification>,
        { placement: "topEnd" }
      );
    } finally {
      setProcessing(false);
      handleSearchforLabour();
    }
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Calculate Salary</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Report by Labour</Radio>
            <Radio value="C">Report by Supervisor </Radio>
            <Radio value="D">Report by Site </Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Date:</Form.ControlLabel>
                    <DatePicker
                      oneTap
                      defaultValue={firstDay}
                      format="MMM-yyyy"
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 200 }}
                    />
                  </Form.Group>
                  &nbsp; &nbsp;
                  <Form.Group>
                    <Form.ControlLabel>Labour Name:</Form.ControlLabel>
                    <SelectPicker
                      data={LabourList}
                      appearance="default"
                      placeholder="Select Labour"
                      style={{ width: 300 }}
                      value={selectedLabour}
                      onSelect={(e, Item) => {
                        setSelectedLabour(e);
                        setselectedNameID(Item.id);
                      }}
                    />
                    &nbsp; &nbsp;
                    <IconButton
                      onClick={handleSearchforLabour}
                      loading={processing}
                      icon={<SearchIcon />}
                    >
                      Search
                    </IconButton>
                    &nbsp; &nbsp;
                    <Button
                      style={{ marginHorizontal: 10 }}
                      color="blue"
                      appearance="primary"
                      onClick={handleOpen}
                    >
                      Travel
                    </Button>
                  </Form.Group>
                </div>
                <div style={{ justifyContent: "center" }}>
                  <div>
                    <h6>
                      {`Travel : ${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(travel)}`}
                      &nbsp; &nbsp; &nbsp;
                      {/*                       {`Total Paid : ${Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(
                        parseInt(
                          balance.Salary_Paid ? balance.Salary_Paid : 0
                        ) +
                          parseInt(
                            balance.Advance_Paid ? balance.Advance_Paid : 0
                          )
                      )}`} */}
                    </h6>
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "C" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Supervisor:</Form.ControlLabel>
                    &nbsp; &nbsp; &nbsp;
                    <SelectPicker
                      data={usersList}
                      appearance="default"
                      placeholder="Default"
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        setselectedName(e);
                        setselectedNameID(Item.id);
                      }}
                      value={selectedName}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <DatePicker
                      oneTap
                      format="MMM-yyyy"
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 130 }}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      color="blue"
                      appearance="default"
                      onClick={handleSups}
                    >
                      Search
                    </Button>
                    &nbsp;
                    <Table2XLSX />
                  </Form.Group>
                </div>
                <div style={{ justifyContent: "center" }}>
                  <div>
                    {`Total : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}`}
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}

          {radio === "D" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.ControlLabel>Site:</Form.ControlLabel>
                    &nbsp; &nbsp; &nbsp;
                    <SelectPicker
                      data={siteList}
                      cleanable={false}
                      appearance="default"
                      placeholder="Select Site"
                      style={{ width: 200 }}
                      key="id"
                      sort={() => {
                        return (a, b) => {
                          return compare(a.value, b.value);
                        };
                      }}
                      onSelect={(e, Item) => {
                        setSitename(e);
                      }}
                      value={sitename}
                    />
                    &nbsp; &nbsp; &nbsp;
                    {/* <DatePicker
                      oneTap
                      format="MMM-yyyy"
                      ranges={[]}
                      onSelect={(e) => setselectedDate(e)}
                      style={{ width: 130 }}
                    /> */}
                    &nbsp; &nbsp; &nbsp;
                    <Button
                      color="blue"
                      appearance="default"
                      onClick={handleSitewiseAttendance}
                    >
                      Search
                    </Button>
                    <Table2XLSX />
                  </Form.Group>
                </div>
                <div style={{ justifyContent: "center" }}>
                  <div>
                    {`Total : ${Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}`}
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "A" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                flex: 0.6,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <Form.Group controlId="radioList1">
                <RadioGroup
                  name="radioList1"
                  inline
                  appearance="picker"
                  defaultValue="Payment"
                  onChange={(e) => {
                    handleRadio1(e);
                  }}
                  value={radio1}
                >
                  <Radio value="Payment">Payments</Radio>
                  <Radio value="Attendance">Attendance </Radio>
                </RadioGroup>

                <hr />
              </Form.Group>
              <Header>
                <h6 style={{ color: "white", fontSize: 15 }}>{radio1}</h6>
              </Header>
              <div style={{ flex: 1, overflow: "auto" }}>
                {radio1 === "Payment" ? (
                  <Table striped bordered hover id="AllPayment">
                    <thead>
                      <tr>
                        {column.map((a) => (
                          <th key={a.field}>{a.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {datalist1.map((a) => (
                        <tr key={a.Date}>
                          <td>{a.Date}</td>
                          <td>{a.Category}</td>
                          <td>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Amount)}
                          </td>
                          <td>{a.Supervisor}</td>
                          <td>{a.Notes}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Table striped bordered hover id="Attendance">
                    <thead>
                      <tr>
                        {column3.map((a) => (
                          <th key={a.field}>{a.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {datalist3.map((a) => (
                        <tr key={a.Date}>
                          <td>{a.Date}</td>
                          <td>{a.Attendance}</td>
                          <td>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.PerDayRate)}
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Salary)}
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Tea)}
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 2,
                            }).format(a.Total)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <div>
                <Header>
                  <h6 style={{ color: "white", fontSize: 20 }}>Salary</h6>
                </Header>
              </div>
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Attendance</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {salary.AttendanceSelectedMonth}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Salary Due</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(salary.SalarySelectedMonth)}
                  </h6>
                </div>
                {!isNaN(travel) && travel > 0 && (
                  <div
                    style={{
                      width: "100%",
                      height: 50,
                      border: 1,
                      borderColor: "#aaa",
                      borderStyle: "solid",
                      marginTop: 10,
                      marginBottom: 10,
                      borderRadius: 5,
                      justifyContent: "space-between",
                      paddingLeft: 10,
                      paddingRight: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h6 style={{ display: "inline-block" }}>Travel </h6>
                    <h6
                      style={{
                        display: "inline-block",
                      }}
                    >
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                      }).format(travel)}
                    </h6>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Balance </h6>
                  <h6
                    style={{
                      display: "inline-block",
                      color: balance.Balance > 0 ? "red" : "limegreen",
                    }}
                  >
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(balance.Balance)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}

        {radio === "C" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                flex: 0.7,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <Header>
                <h6 style={{ color: "white", fontSize: 20 }}>All Attendance</h6>
              </Header>
              <div style={{ flex: 1, overflow: "auto" }}>
                <Table striped bordered hover id="AllAttendance">
                  <thead>
                    <tr>
                      {column2.map((a) => (
                        <th key={a.field}>{a.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {datalist2.map((a) => (
                      <tr key={a.SrNo}>
                        <td>{a.Name}</td>
                        <td>{a.Date}</td>
                        <td>{a.Attendance}</td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.PerDayRate)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Salary)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Tea)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Total)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              style={{
                flex: 0.3,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <div>
                <Header>
                  <h6 style={{ color: "white", fontSize: 20 }}>Total Amount</h6>
                </Header>
              </div>
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Labour</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {count.LabourCount}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Attendance</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {count.TotalAttendance}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Amount </h6>
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}

        {radio === "D" && (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                flex: 0.7,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <Header>
                <h6 style={{ color: "white", fontSize: 20 }}>All Attendance</h6>
              </Header>
              <div style={{ flex: 1, overflow: "auto" }}>
                <Table striped bordered hover id="AllAttendance">
                  <thead>
                    <tr>
                      {column2.map((a) => (
                        <th key={a.field}>{a.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {datalist2.map((a) => (
                      <tr key={a.SrNo}>
                        <td>{a.Name}</td>
                        <td>{a.Date}</td>
                        <td>{a.Attendance}</td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.PerDayRate)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Salary)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Tea)}
                        </td>
                        <td>
                          {Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 2,
                          }).format(a.Total)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              style={{
                flex: 0.3,
                display: "flex",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <div>
                <Header>
                  <h6 style={{ color: "white", fontSize: 20 }}>Total Amount</h6>
                </Header>
              </div>
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Labour</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {count.LabourCount}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Attendance</h6>
                  <h6 style={{ display: "inline-block" }}>
                    {count.TotalAttendance}
                  </h6>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    border: 1,
                    borderColor: "#aaa",
                    borderStyle: "solid",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>Amount </h6>
                  <h6
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(count.TotalAmount)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Description: ===================================================== */}
      <div>
        <Modal open={open} onClose={handleClose} size="xs">
          <Modal.Header>
            <Modal.Title>{`Travel - ${selectedLabour}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form layout="inline" fluid>
              <Form.Group controlId="textarea-9">
                <Form.ControlLabel>Amount : </Form.ControlLabel>
                <InputGroup style={{ width: "200px" }}>
                  <InputGroup.Addon>₹</InputGroup.Addon>
                  <Input
                    id="textarea-9"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e);
                    }}
                  />
                  <InputGroup.Addon>.00</InputGroup.Addon>
                </InputGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAddTravel} appearance="primary">
              Submit
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default GetSalary;
