import React, { useState } from "react";
import {
  Button,
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  DatePicker,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";
import * as XLSX from "xlsx";
import { firstDay } from "../Constants";
import ExportIcon from "@rsuite/icons/Export";
import { GetLastDay, Table2XLSX } from "../functions";

const Summary = () => {
  const [radio, setRadio] = useState("A");

  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedNameID, setselectedNameID] = useState("");
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const toaster = useToaster();
  const [selectedLabour, setSelectedLabour] = useState("");
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  const [attendance, setAttendance] = useState("");
  const [amount, setAmount] = useState("");

  const [column, setColumn] = useState([
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "Travel", title: "Travel" },
    { field: "SalaryDue", title: "SalaryDue" },
    { field: "Balance", title: "Balance" },
  ]);

  const column2 = [
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "Travel", title: "Travel" },
    { field: "SalaryDue", title: "SalaryDue" },
    { field: "Balance", title: "Balance" },
  ];

  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("https://api.laxmaninterior.in/GetLabours.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setLabourList((a) => {
              return [...a, { id: r.SrNo, value: r.Name, label: r.Name }];
            });
          });
        }
        if (data1.status === 0) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setdataList([]);
    setdataList2([]);
    setRadio(e);
  };

  function handleExport() {
    setProcessing1(true);

    const table = document.getElementById("TableData");
    const wb = XLSX.utils.table_to_book(table);
    XLSX.writeFile(wb, "LaboursList.xlsx");

    setProcessing1(false);
  }

  const handleSearchforLabour = () => {
    setProcessing(true);
    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select salary month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = {
      Month: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetMonthlySalarySummary.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);
        if (data1.status === 1) {
          setdataList(data1.Arr);
          setProcessing(false);
        }
        if (data1.status === 0) {
          setdataList([]);
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
        setProcessing(false);
      });
  };

  const handleBalanceSearchforLabour = () => {
    setProcessing1(true);
    if (selectedDate === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select salary month
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing1(false);
      return;
    }
    const datatoSend = {
      Month: moment(GetLastDay(selectedDate)).format("YYYY-MM-DD"),
    };
    console.log(datatoSend);
    axios
      .post(
        "https://api.laxmaninterior.in/GetMonthlySalarySummaryBalance.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);
        if (data1.status === 1) {
          setdataList2(data1.Arr);
          setProcessing1(false);
        }
        if (data1.status === 0) {
          setdataList2([]);
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
          setProcessing1(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
        setProcessing1(false);
      });
  };
  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>{`Summary (${
          radio === "A" ? dataList.length : dataList2.length
        })`}</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Summary</Radio>
            <Radio value="D">Balance</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>
        <Form layout="inline">
          {radio === "A" && (
            <>
              <Form.Group>
                <Form.ControlLabel>Month:</Form.ControlLabel>
                <DatePicker
                  oneTap
                  format="MMM-yyyy"
                  defaultValue={firstDay}
                  ranges={[]}
                  onSelect={(e) => setselectedDate(e)}
                  style={{ width: 200 }}
                />
              </Form.Group>
              <Form.Group>
                <IconButton
                  onClick={handleSearchforLabour}
                  loading={processing}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>

                <Button
                  style={{ marginHorizontal: 10 }}
                  color="green"
                  appearance="subtle"
                  onClick={handleExport}
                  loading={processing1}
                >
                  <ExportIcon style={{ margin: "5px" }} /> Export
                </Button>
              </Form.Group>
            </>
          )}

          {radio === "D" && (
            <>
              <Form.Group>
                <Form.ControlLabel>Month:</Form.ControlLabel>
                <DatePicker
                  oneTap
                  format="MMM-yyyy"
                  defaultValue={firstDay}
                  ranges={[]}
                  onSelect={(e) => setselectedDate(e)}
                  style={{ width: 200 }}
                />
                &nbsp;
                <IconButton
                  onClick={handleBalanceSearchforLabour}
                  loading={processing1}
                  icon={<SearchIcon />}
                >
                  Search
                </IconButton>
                &nbsp;
                <Table2XLSX />
              </Form.Group>
            </>
          )}
          {/* <div
            style={{
              display: "inline",
              alignItems: "center",
            }}
          >
            {`Total Attendance : ${Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 2,
            }).format(attendance)}`}
            &nbsp; &nbsp;
            {`Salary : ${Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 2,
            }).format(amount)}`}
          </div> */}
        </Form>
      </div>
      <div className="InputContainer">
        {radio === "A" && (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a[0]}>
                  <td>{a[1]}</td>
                  <td>{a[2]}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[9])}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[3])}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[8])}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "D" && (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList2.map((a) => (
                <tr key={a[0]}>
                  <td>{a[1]}</td>
                  <td>{a[2]}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[9])}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[3])}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a[8])}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Summary;
