import React, { useMemo, useState } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  useToaster,
  Notification,
  Tooltip,
  Whisper,
  IconButton,
} from "rsuite";
import Header from "../component/Header";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import Table from "react-bootstrap/Table";
import axios from "axios";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const Suppliers = () => {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [value, setValue] = React.useState({
    SupplierName: "",
    SupplierDesc: "",
  });

  const column = React.useMemo(() => [
    { field: "SupplierName", title: "Supplier" },
    { field: "Desc", title: "Notes" },
    /*     { field: "Edit", title: "Edit" },
    { field: "Delete", title: "Delete" }, */
  ]);
  /*   const [column, setColumn] = useState([
    { field: "SupplierName", title: "Supplier" },
    { field: "Desc", title: "Notes" },
     { field: "Edit", title: "Edit" },
    { field: "Delete", title: "Delete" }, 
  ]);
 */

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);
  const toaster = useToaster();

  const handleRefresh = () => {
    setProcessing1(true);

    axios
      .get("https://api.laxmaninterior.in/GetSuppliers.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSupplierList(data1.Data);
        }
        if (data1.status === 0) {
          setSupplierList([]);
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  };

  const SaveData = async () => {
    setProcessing(true);

    if (value.SupplierName === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Supplier Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    if (value.SupplierDesc === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter small Description
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const data = {
      SupplierName: value.SupplierName,
      SupplierDesc: value.SupplierDesc,
    };
    axios
      .post("https://api.laxmaninterior.in/AddSupplier.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );

          clearInput();
          handleRefresh();
          setProcessing(false);
        }
        if (result.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${value.SupplierName} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );

          //clearInput();
          setProcessing(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  function clearInput() {
    setValue({
      SupplierName: "",
      SupplierDesc: "",
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add New</h4>
      </Header>
      <div className="Subbody">
        <Form layout="inline">
          <Form.Group>
            <Form.ControlLabel>Supplier</Form.ControlLabel>
            <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
              <Input
                style={{ width: 300 }}
                placeholder="Enter supplier name"
                value={value.SupplierName}
                onChange={(e) =>
                  setValue((a) => {
                    return { ...a, SupplierName: e };
                  })
                }
              />
            </Whisper>
          </Form.Group>

          <Form.Group controlId="textarea-6">
            <Form.ControlLabel>Additional</Form.ControlLabel>
            <Form.Control
              name="textarea"
              rows={1}
              accepter={Textarea}
              value={value.SupplierDesc}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, SupplierDesc: e };
                })
              }
            />
          </Form.Group>

          <IconButton
            color="green"
            appearance="primary"
            onClick={SaveData}
            loading={processing}
            icon={<AddOutlineIcon />}
          >
            Add
          </IconButton>

          <Button
            color="green"
            appearance="primary"
            onClick={handleRefresh}
            loading={processing1}
          >
            <ReloadIcon style={{ margin: "5px" }} /> Refresh
          </Button>
        </Form>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`List of Suppliers (${supplierList.length})`}</h5>
      </Header>
      <div className="InputContainer">
        <Table>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {supplierList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.SupplierName}</td>
                <td>{a.SupplierDesc}</td>
                {/*                 <td width={60}>
                  <IconButton
                    icon={<EditIcon />}
                    color="blue"
                    disabled
                    appearance="primary"
                    circle
                    onClick={() => {}}
                  />
                </td>
                <td width={60}>
                  <IconButton
                    disabled
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() => {}}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Suppliers;
